import image0Url from '@assets/lottie/signIn/image_0.png';
import image1Url from '@assets/lottie/signIn/image_1.png';
import image2Url from '@assets/lottie/signIn/image_2.png';
import image3Url from '@assets/lottie/signIn/image_3.png';
import { loadImage } from '@helpers/loadImage';

export default new Promise((resolve) => {
  Promise.all([
    loadImage(image0Url),
    loadImage(image1Url),
    loadImage(image2Url),
    loadImage(image3Url)
  ]).finally(() => {
    resolve({
      v: '5.9.4',
      fr: 30,
      ip: 0,
      op: 77,
      w: 752,
      h: 768,
      ddd: 0,
      assets: [
        {
          id: 'image_0',
          w: 401,
          h: 145,
          u: '',
          p: image0Url,
          e: 1
        },
        {
          id: 'image_1',
          w: 161,
          h: 161,
          u: '',
          p: image1Url,
          e: 1
        },
        {
          id: 'image_2',
          w: 512,
          h: 512,
          u: '',
          p: image2Url,
          e: 1
        },
        {
          id: 'image_3',
          w: 1920,
          h: 1080,
          u: '',
          p: image3Url,
          e: 1
        },
        {
          id: 'comp_0',
          fr: 25,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 2.4,
                      s: [0]
                    },
                    { t: 16.8, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -14.4,
                      s: [14]
                    },
                    { t: 32.4, s: [-12] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -14.4,
                      s: [1512.171, 698.659, 0],
                      to: [-29.639, -30.354, 0],
                      ti: [117.826, -88.42, 0]
                    },
                    { t: 32.4, s: [1252.579, 680.137, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [67, 51, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-10.52, -2.023],
                            [0, 0],
                            [2.132, -10.499],
                            [0, 0],
                            [10.52, 2.023],
                            [-2.132, 10.499],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [10.52, 2.023],
                            [0, 0],
                            [-2.132, 10.499],
                            [-10.52, -2.023],
                            [0, 0],
                            [2.131, -10.499]
                          ],
                          v: [
                            [3.871, -19.067],
                            [3.871, -19.067],
                            [19.06, 3.607],
                            [19.038, 3.719],
                            [-3.871, 19.067],
                            [-19.06, -3.607],
                            [-19.037, -3.719]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.690196078431, 0.854902020623, 0.972549079446, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [88.76, 34.333], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.14, 0.136],
                            [-0.182, 0.095],
                            [-0.211, 0.041],
                            [-0.204, -0.039],
                            [-0.054, -0.018],
                            [-0.062, -0.02],
                            [0, 0],
                            [0.101, 0.028],
                            [0.094, 0.018],
                            [0.222, -0.054],
                            [0.184, -0.152],
                            [0.137, -0.242],
                            [0.065, -0.337],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.072, -0.158],
                            [0.148, -0.134],
                            [0.191, -0.101],
                            [0.212, -0.04],
                            [0.157, 0.031],
                            [0.061, 0.02],
                            [0, 0],
                            [-0.091, -0.034],
                            [-0.091, -0.034],
                            [-0.228, -0.044],
                            [-0.213, 0.049],
                            [-0.183, 0.144],
                            [-0.136, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [34.948, 5.147],
                            [36.209, 5.392],
                            [35.98, 6.57],
                            [36.003, 6.575],
                            [36.32, 6.135],
                            [36.815, 5.791],
                            [37.419, 5.578],
                            [38.043, 5.577],
                            [38.358, 5.651],
                            [38.543, 5.711],
                            [38.29, 7.007],
                            [38.003, 6.914],
                            [37.725, 6.836],
                            [37.049, 6.851],
                            [36.454, 7.151],
                            [35.973, 7.73],
                            [35.67, 8.6],
                            [35.107, 11.498],
                            [33.764, 11.237]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.02, -0.233],
                            [-0.101, -0.191],
                            [-0.184, -0.142],
                            [-0.283, -0.055],
                            [-0.267, 0.12],
                            [-0.183, 0.315],
                            [0, 0],
                            [0.223, -0.226],
                            [0.278, -0.133],
                            [0.323, -0.027],
                            [0.33, 0.064],
                            [0.339, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.418],
                            [-0.091, 0.472],
                            [-0.209, 0.359],
                            [-0.308, 0.242],
                            [-0.395, 0.103],
                            [-0.455, -0.089],
                            [-0.337, -0.278],
                            [-0.188, -0.379],
                            [-0.026, -0.454],
                            [0.144, -0.445]
                          ],
                          o: [
                            [-0.046, 0.236],
                            [0.028, 0.233],
                            [0.102, 0.191],
                            [0.186, 0.134],
                            [0.393, 0.076],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.135, 0.316],
                            [-0.223, 0.225],
                            [-0.277, 0.126],
                            [-0.317, 0.036],
                            [-0.479, -0.093],
                            [-0.338, -0.229],
                            [-0.196, -0.332],
                            [-0.049, -0.417],
                            [0.084, -0.432],
                            [0.217, -0.365],
                            [0.317, -0.248],
                            [0.395, -0.103],
                            [0.479, 0.093],
                            [0.346, 0.271],
                            [0.187, 0.378],
                            [0.036, 0.446],
                            [0, 0]
                          ],
                          v: [
                            [28.792, 7.471],
                            [28.753, 8.173],
                            [28.946, 8.809],
                            [29.376, 9.308],
                            [30.079, 9.592],
                            [31.069, 9.527],
                            [31.759, 8.867],
                            [33.032, 9.114],
                            [32.495, 9.927],
                            [31.742, 10.465],
                            [30.842, 10.694],
                            [29.872, 10.652],
                            [28.646, 10.169],
                            [27.831, 9.326],
                            [27.451, 8.201],
                            [27.514, 6.868],
                            [27.954, 5.682],
                            [28.742, 4.772],
                            [29.81, 4.246],
                            [31.085, 4.225],
                            [32.31, 4.781],
                            [33.111, 5.756],
                            [33.43, 7.004],
                            [33.268, 8.341]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.04, 0.211],
                            [0.106, 0.167],
                            [0.172, 0.124],
                            [0.228, 0.044],
                            [0.213, -0.048],
                            [0.17, -0.121],
                            [0.124, -0.18],
                            [0.052, -0.227]
                          ],
                          o: [
                            [0.025, -0.215],
                            [-0.033, -0.21],
                            [-0.097, -0.174],
                            [-0.162, -0.129],
                            [-0.235, -0.046],
                            [-0.204, 0.042],
                            [-0.161, 0.116],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [32.096, 7.197],
                            [32.075, 6.557],
                            [31.867, 5.991],
                            [31.464, 5.545],
                            [30.878, 5.285],
                            [30.206, 5.289],
                            [29.644, 5.534],
                            [29.216, 5.977],
                            [28.964, 6.588]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.052, 0.267],
                            [0.02, 0.273],
                            [0.103, 0.224],
                            [0.189, 0.159],
                            [0.275, 0.053],
                            [0.367, -0.336],
                            [0.127, -0.652],
                            [-0.019, -0.281],
                            [-0.113, -0.218],
                            [-0.183, -0.15],
                            [-0.275, -0.054],
                            [-0.236, 0.085],
                            [-0.171, 0.17],
                            [-0.104, 0.241]
                          ],
                          o: [
                            [0.054, -0.275],
                            [-0.02, -0.272],
                            [-0.102, -0.231],
                            [-0.188, -0.166],
                            [-0.566, -0.11],
                            [-0.359, 0.338],
                            [-0.06, 0.306],
                            [0.028, 0.275],
                            [0.112, 0.217],
                            [0.191, 0.151],
                            [0.306, 0.059],
                            [0.237, -0.084],
                            [0.181, -0.177],
                            [0.113, -0.247]
                          ],
                          v: [
                            [25.331, 6.444],
                            [25.382, 5.622],
                            [25.196, 4.877],
                            [24.761, 4.291],
                            [24.066, 3.961],
                            [22.668, 4.3],
                            [21.94, 5.785],
                            [21.878, 6.665],
                            [22.09, 7.403],
                            [22.533, 7.954],
                            [23.231, 8.261],
                            [24.044, 8.223],
                            [24.657, 7.841],
                            [25.085, 7.215]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 4,
                      ty: 'sh',
                      ix: 5,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.157, 0.091],
                            [-0.173, 0.048],
                            [-0.181, 0.005],
                            [-0.173, -0.033],
                            [-0.32, -0.241],
                            [-0.178, -0.344],
                            [-0.042, -0.416],
                            [0.087, -0.448],
                            [0.185, -0.363],
                            [0.278, -0.255],
                            [0.365, -0.116],
                            [0.447, 0.087],
                            [0.206, 0.073],
                            [0.173, 0.116],
                            [0.133, 0.157],
                            [0.062, 0.224],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.123, -0.131],
                            [0.156, -0.092],
                            [0.181, -0.055],
                            [0.19, -0.012],
                            [0.479, 0.093],
                            [0.329, 0.235],
                            [0.179, 0.336],
                            [0.041, 0.415],
                            [-0.08, 0.408],
                            [-0.176, 0.365],
                            [-0.268, 0.25],
                            [-0.363, 0.109],
                            [-0.205, -0.04],
                            [-0.199, -0.063],
                            [-0.173, -0.115],
                            [-0.124, -0.154],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [21.688, 0.161],
                            [23.03, 0.422],
                            [22.426, 3.532],
                            [22.45, 3.537],
                            [22.87, 3.203],
                            [23.362, 2.993],
                            [23.905, 2.903],
                            [24.45, 2.935],
                            [25.648, 3.437],
                            [26.408, 4.306],
                            [26.738, 5.434],
                            [26.67, 6.729],
                            [26.273, 7.886],
                            [25.591, 8.817],
                            [24.642, 9.366],
                            [23.425, 9.399],
                            [22.81, 9.23],
                            [22.251, 8.962],
                            [21.792, 8.555],
                            [21.512, 7.987],
                            [21.487, 7.983],
                            [21.326, 8.819],
                            [20.053, 8.572]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 5,
                      ty: 'sh',
                      ix: 6,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.142, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.01],
                            [-0.244, -0.047],
                            [-0.282, -0.226],
                            [-0.066, -0.371],
                            [-0.333, 0.123],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.739],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.2],
                            [0.051, 0.156],
                            [0.134, 0.107],
                            [0.259, 0.05],
                            [0.259, -0.243],
                            [0.101, -0.519],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.243],
                            [0.408, 0.079],
                            [0.179, -0.038],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.251],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.129],
                            [0.142, -0.103],
                            [0.166, -0.057],
                            [0.199, -0.019],
                            [0.369, 0.072],
                            [0.29, 0.227],
                            [0.289, -0.27],
                            [0.334, -0.122],
                            [0.644, 0.125],
                            [0.301, 0.384],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.244],
                            [0.024, -0.207],
                            [-0.042, -0.163],
                            [-0.134, -0.108],
                            [-0.456, -0.089],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.249],
                            [-0.173, -0.034],
                            [-0.171, 0.04],
                            [-0.143, 0.111],
                            [-0.109, 0.182],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [10.951, 0.483],
                            [12.223, 0.73],
                            [12.06, 1.578],
                            [12.095, 1.585],
                            [12.504, 1.237],
                            [12.955, 0.994],
                            [13.501, 0.893],
                            [14.164, 0.936],
                            [15.141, 1.383],
                            [15.675, 2.281],
                            [16.608, 1.692],
                            [17.78, 1.639],
                            [19.185, 2.401],
                            [19.419, 4.085],
                            [18.602, 8.29],
                            [17.259, 8.029],
                            [17.951, 4.472],
                            [18.055, 3.807],
                            [18.016, 3.262],
                            [17.752, 2.856],
                            [17.163, 2.619],
                            [16.091, 2.851],
                            [15.55, 3.993],
                            [14.857, 7.562],
                            [13.513, 7.301],
                            [14.273, 3.39],
                            [14.223, 2.39],
                            [13.451, 1.898],
                            [12.924, 1.905],
                            [12.44, 2.129],
                            [12.05, 2.567],
                            [11.814, 3.217],
                            [11.111, 6.834],
                            [9.768, 6.573]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 6,
                      ty: 'sh',
                      ix: 7,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.02, -0.232],
                            [-0.101, -0.191],
                            [-0.185, -0.142],
                            [-0.283, -0.055],
                            [-0.268, 0.119],
                            [-0.184, 0.314],
                            [0, 0],
                            [0.223, -0.225],
                            [0.278, -0.134],
                            [0.323, -0.027],
                            [0.329, 0.064],
                            [0.339, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.419],
                            [-0.091, 0.471],
                            [-0.209, 0.359],
                            [-0.308, 0.241],
                            [-0.395, 0.102],
                            [-0.455, -0.089],
                            [-0.338, -0.277],
                            [-0.187, -0.378],
                            [-0.026, -0.453],
                            [0.143, -0.445]
                          ],
                          o: [
                            [-0.046, 0.235],
                            [0.028, 0.234],
                            [0.102, 0.191],
                            [0.186, 0.134],
                            [0.392, 0.077],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.135, 0.316],
                            [-0.223, 0.226],
                            [-0.277, 0.125],
                            [-0.316, 0.036],
                            [-0.479, -0.093],
                            [-0.338, -0.228],
                            [-0.196, -0.332],
                            [-0.05, -0.417],
                            [0.084, -0.432],
                            [0.217, -0.365],
                            [0.317, -0.249],
                            [0.395, -0.103],
                            [0.479, 0.093],
                            [0.346, 0.272],
                            [0.188, 0.379],
                            [0.035, 0.447],
                            [0, 0]
                          ],
                          v: [
                            [4.795, 2.807],
                            [4.757, 3.508],
                            [4.951, 4.145],
                            [5.379, 4.644],
                            [6.082, 4.927],
                            [7.073, 4.863],
                            [7.763, 4.203],
                            [9.035, 4.45],
                            [8.498, 5.262],
                            [7.747, 5.801],
                            [6.846, 6.029],
                            [5.877, 5.987],
                            [4.649, 5.504],
                            [3.836, 4.662],
                            [3.456, 3.536],
                            [3.518, 2.204],
                            [3.957, 1.018],
                            [4.746, 0.108],
                            [5.814, -0.418],
                            [7.089, -0.439],
                            [8.314, 0.116],
                            [9.113, 1.091],
                            [9.434, 2.339],
                            [9.272, 3.677]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 7,
                      ty: 'sh',
                      ix: 8,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.041, 0.212],
                            [0.106, 0.167],
                            [0.172, 0.123],
                            [0.228, 0.044],
                            [0.213, -0.048],
                            [0.17, -0.122],
                            [0.124, -0.179],
                            [0.052, -0.226]
                          ],
                          o: [
                            [0.025, -0.215],
                            [-0.032, -0.21],
                            [-0.097, -0.173],
                            [-0.162, -0.129],
                            [-0.236, -0.046],
                            [-0.204, 0.042],
                            [-0.161, 0.115],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [8.101, 2.532],
                            [8.077, 1.892],
                            [7.871, 1.326],
                            [7.467, 0.881],
                            [6.883, 0.621],
                            [6.21, 0.624],
                            [5.648, 0.87],
                            [5.221, 1.312],
                            [4.966, 1.923]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 8,
                      ty: 'sh',
                      ix: 9,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.142, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.01],
                            [-0.244, -0.047],
                            [-0.282, -0.226],
                            [-0.067, -0.371],
                            [-0.333, 0.122],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.738],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.199],
                            [0.051, 0.157],
                            [0.134, 0.108],
                            [0.259, 0.051],
                            [0.259, -0.243],
                            [0.101, -0.518],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.243],
                            [0.408, 0.079],
                            [0.179, -0.039],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.252],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.129],
                            [0.142, -0.103],
                            [0.166, -0.058],
                            [0.199, -0.018],
                            [0.369, 0.072],
                            [0.29, 0.227],
                            [0.289, -0.269],
                            [0.334, -0.123],
                            [0.644, 0.125],
                            [0.301, 0.385],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.244],
                            [0.024, -0.208],
                            [-0.042, -0.163],
                            [-0.134, -0.107],
                            [-0.456, -0.088],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.248],
                            [-0.173, -0.034],
                            [-0.171, 0.04],
                            [-0.143, 0.111],
                            [-0.109, 0.183],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-5.843, -2.782],
                            [-4.571, -2.535],
                            [-4.737, -1.687],
                            [-4.701, -1.68],
                            [-4.29, -2.028],
                            [-3.841, -2.27],
                            [-3.294, -2.372],
                            [-2.63, -2.329],
                            [-1.654, -1.882],
                            [-1.119, -0.984],
                            [-0.186, -1.572],
                            [0.986, -1.626],
                            [2.389, -0.864],
                            [2.624, 0.82],
                            [1.808, 5.025],
                            [0.465, 4.764],
                            [1.157, 1.207],
                            [1.261, 0.543],
                            [1.221, -0.003],
                            [0.956, -0.409],
                            [0.367, -0.646],
                            [-0.705, -0.414],
                            [-1.244, 0.728],
                            [-1.939, 4.297],
                            [-3.281, 4.036],
                            [-2.522, 0.125],
                            [-2.571, -0.875],
                            [-3.343, -1.367],
                            [-3.871, -1.359],
                            [-4.354, -1.136],
                            [-4.744, -0.698],
                            [-4.982, -0.047],
                            [-5.685, 3.569],
                            [-7.028, 3.308]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 9,
                      ty: 'sh',
                      ix: 10,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.143, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.011],
                            [-0.244, -0.048],
                            [-0.282, -0.226],
                            [-0.067, -0.372],
                            [-0.333, 0.123],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.738],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.199],
                            [0.051, 0.157],
                            [0.134, 0.107],
                            [0.259, 0.05],
                            [0.259, -0.243],
                            [0.101, -0.518],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.242],
                            [0.408, 0.08],
                            [0.179, -0.039],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.251],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.13],
                            [0.142, -0.102],
                            [0.166, -0.057],
                            [0.199, -0.018],
                            [0.369, 0.072],
                            [0.29, 0.228],
                            [0.289, -0.27],
                            [0.334, -0.122],
                            [0.644, 0.125],
                            [0.301, 0.384],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.243],
                            [0.024, -0.207],
                            [-0.042, -0.163],
                            [-0.134, -0.108],
                            [-0.456, -0.089],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.249],
                            [-0.173, -0.033],
                            [-0.171, 0.04],
                            [-0.143, 0.11],
                            [-0.109, 0.183],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-19.372, -5.412],
                            [-18.1, -5.164],
                            [-18.264, -4.316],
                            [-18.228, -4.309],
                            [-17.819, -4.658],
                            [-17.368, -4.9],
                            [-16.822, -5.002],
                            [-16.159, -4.958],
                            [-15.182, -4.512],
                            [-14.648, -3.613],
                            [-13.715, -4.202],
                            [-12.543, -4.255],
                            [-11.138, -3.493],
                            [-10.904, -1.81],
                            [-11.721, 2.396],
                            [-13.064, 2.135],
                            [-12.372, -1.423],
                            [-12.268, -2.087],
                            [-12.307, -2.633],
                            [-12.571, -3.038],
                            [-13.16, -3.275],
                            [-14.232, -3.044],
                            [-14.773, -1.902],
                            [-15.466, 1.668],
                            [-16.81, 1.407],
                            [-16.049, -2.504],
                            [-16.1, -3.504],
                            [-16.872, -3.997],
                            [-17.398, -3.989],
                            [-17.883, -3.765],
                            [-18.273, -3.328],
                            [-18.509, -2.677],
                            [-19.212, 0.94],
                            [-20.555, 0.678]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 10,
                      ty: 'sh',
                      ix: 11,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.304, 0.026],
                            [0.31, 0.003],
                            [0.142, -0.021],
                            [0.117, -0.059],
                            [0.087, -0.114],
                            [0.034, -0.172],
                            [-0.066, -0.119],
                            [-0.105, -0.085],
                            [-0.137, -0.051],
                            [-0.118, -0.023],
                            [-0.18, 0.006],
                            [-0.167, 0.065],
                            [-0.129, 0.122],
                            [-0.041, 0.212]
                          ],
                          o: [
                            [-0.23, 0.094],
                            [-0.303, -0.035],
                            [-0.146, -0.004],
                            [-0.142, 0.013],
                            [-0.116, 0.051],
                            [-0.078, 0.107],
                            [-0.029, 0.15],
                            [0.067, 0.118],
                            [0.115, 0.08],
                            [0.145, 0.053],
                            [0.149, 0.029],
                            [0.181, -0.005],
                            [0.176, -0.064],
                            [0.132, -0.129],
                            [0, 0]
                          ],
                          v: [
                            [-22.749, -2.914],
                            [-23.551, -2.813],
                            [-24.471, -2.87],
                            [-24.903, -2.844],
                            [-25.29, -2.736],
                            [-25.596, -2.489],
                            [-25.763, -2.07],
                            [-25.706, -1.667],
                            [-25.449, -1.361],
                            [-25.07, -1.165],
                            [-24.676, -1.052],
                            [-24.182, -1.017],
                            [-23.66, -1.123],
                            [-23.203, -1.401],
                            [-22.945, -1.913]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 11,
                      ty: 'sh',
                      ix: 12,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.026, -0.079],
                            [-0.125, -0.024],
                            [-0.055, -0.011],
                            [-0.074, 0.002],
                            [0, 0],
                            [0.082, -0.001],
                            [0.082, 0],
                            [0.08, 0.007],
                            [0.055, 0.01],
                            [0.159, 0.145],
                            [0.001, 0.286],
                            [0.416, -0.041],
                            [0.361, 0.07],
                            [0.236, 0.127],
                            [0.169, 0.18],
                            [0.075, 0.242],
                            [-0.058, 0.299],
                            [-0.18, 0.21],
                            [-0.246, 0.091],
                            [-0.286, 0.001],
                            [-0.288, -0.023],
                            [-0.223, -0.027],
                            [-0.174, 0.016],
                            [-0.112, 0.076],
                            [-0.038, 0.197],
                            [0.065, 0.127],
                            [0.115, 0.08],
                            [0.146, 0.044],
                            [0.134, 0.026],
                            [0.274, -0.11],
                            [0.095, -0.324],
                            [0, 0],
                            [-0.216, 0.227],
                            [-0.282, 0.108],
                            [-0.334, 0],
                            [-0.338, -0.066],
                            [-0.278, -0.12],
                            [-0.2, -0.186],
                            [-0.097, -0.256],
                            [0.062, -0.322]
                          ],
                          o: [
                            [-0.032, 0.165],
                            [0.033, 0.08],
                            [0.04, 0.008],
                            [0.055, 0.01],
                            [0, 0],
                            [-0.05, 0.007],
                            [-0.075, 0.01],
                            [-0.082, 0.001],
                            [-0.08, -0.008],
                            [-0.275, -0.054],
                            [-0.159, -0.145],
                            [-0.318, 0.207],
                            [-0.407, 0.043],
                            [-0.275, -0.053],
                            [-0.238, -0.119],
                            [-0.159, -0.185],
                            [-0.065, -0.25],
                            [0.073, -0.377],
                            [0.187, -0.208],
                            [0.254, -0.089],
                            [0.295, -0.008],
                            [0.253, 0.001],
                            [0.224, 0.019],
                            [0.182, -0.013],
                            [0.122, -0.082],
                            [0.034, -0.172],
                            [-0.057, -0.125],
                            [-0.105, -0.085],
                            [-0.145, -0.053],
                            [-0.377, -0.073],
                            [-0.274, 0.109],
                            [0, 0],
                            [0.1, -0.388],
                            [0.215, -0.227],
                            [0.29, -0.107],
                            [0.334, -0.001],
                            [0.298, 0.058],
                            [0.278, 0.119],
                            [0.208, 0.187],
                            [0.098, 0.247],
                            [0, 0]
                          ],
                          v: [
                            [-21.737, -0.957],
                            [-21.747, -0.592],
                            [-21.508, -0.436],
                            [-21.367, -0.408],
                            [-21.174, -0.395],
                            [-21.354, 0.535],
                            [-21.552, 0.546],
                            [-21.788, 0.561],
                            [-22.029, 0.551],
                            [-22.232, 0.524],
                            [-22.883, 0.226],
                            [-23.124, -0.42],
                            [-24.225, -0.047],
                            [-25.378, -0.088],
                            [-26.143, -0.359],
                            [-26.753, -0.808],
                            [-27.106, -1.45],
                            [-27.117, -2.272],
                            [-26.737, -3.152],
                            [-26.088, -3.6],
                            [-25.279, -3.736],
                            [-24.404, -3.713],
                            [-23.69, -3.672],
                            [-23.092, -3.666],
                            [-22.651, -3.8],
                            [-22.411, -4.218],
                            [-22.458, -4.667],
                            [-22.716, -4.974],
                            [-23.093, -5.169],
                            [-23.512, -5.287],
                            [-24.487, -5.232],
                            [-25.041, -4.582],
                            [-26.384, -4.843],
                            [-25.911, -5.766],
                            [-25.166, -6.269],
                            [-24.231, -6.429],
                            [-23.222, -6.331],
                            [-22.357, -6.065],
                            [-21.639, -5.608],
                            [-21.181, -4.944],
                            [-21.128, -4.09]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 12,
                      ty: 'sh',
                      ix: 13,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.021, -0.232],
                            [-0.102, -0.19],
                            [-0.184, -0.141],
                            [-0.283, -0.055],
                            [-0.268, 0.12],
                            [-0.183, 0.315],
                            [0, 0],
                            [0.223, -0.226],
                            [0.279, -0.133],
                            [0.323, -0.027],
                            [0.33, 0.064],
                            [0.338, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.418],
                            [-0.092, 0.471],
                            [-0.208, 0.359],
                            [-0.308, 0.242],
                            [-0.395, 0.103],
                            [-0.456, -0.088],
                            [-0.337, -0.277],
                            [-0.187, -0.379],
                            [-0.026, -0.454],
                            [0.144, -0.445]
                          ],
                          o: [
                            [-0.046, 0.236],
                            [0.028, 0.233],
                            [0.101, 0.191],
                            [0.186, 0.134],
                            [0.393, 0.076],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.134, 0.317],
                            [-0.224, 0.225],
                            [-0.277, 0.126],
                            [-0.317, 0.036],
                            [-0.479, -0.093],
                            [-0.339, -0.229],
                            [-0.197, -0.331],
                            [-0.049, -0.417],
                            [0.083, -0.433],
                            [0.218, -0.365],
                            [0.317, -0.248],
                            [0.395, -0.102],
                            [0.479, 0.093],
                            [0.347, 0.271],
                            [0.187, 0.378],
                            [0.035, 0.447],
                            [0, 0]
                          ],
                          v: [
                            [-32.074, -4.36],
                            [-32.112, -3.658],
                            [-31.918, -3.022],
                            [-31.489, -2.523],
                            [-30.786, -2.239],
                            [-29.795, -2.304],
                            [-29.106, -2.964],
                            [-27.833, -2.717],
                            [-28.369, -1.904],
                            [-29.122, -1.366],
                            [-30.023, -1.137],
                            [-30.992, -1.179],
                            [-32.218, -1.662],
                            [-33.033, -2.505],
                            [-33.414, -3.63],
                            [-33.349, -4.962],
                            [-32.911, -6.149],
                            [-32.123, -7.059],
                            [-31.055, -7.585],
                            [-29.779, -7.606],
                            [-28.555, -7.05],
                            [-27.754, -6.075],
                            [-27.435, -4.827],
                            [-27.597, -3.49]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 13,
                      ty: 'sh',
                      ix: 14,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.04, 0.211],
                            [0.106, 0.167],
                            [0.171, 0.123],
                            [0.227, 0.045],
                            [0.214, -0.048],
                            [0.171, -0.121],
                            [0.125, -0.18],
                            [0.052, -0.227]
                          ],
                          o: [
                            [0.026, -0.215],
                            [-0.033, -0.21],
                            [-0.097, -0.174],
                            [-0.163, -0.13],
                            [-0.236, -0.046],
                            [-0.203, 0.042],
                            [-0.161, 0.116],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [-28.768, -4.634],
                            [-28.79, -5.274],
                            [-28.998, -5.84],
                            [-29.4, -6.285],
                            [-29.986, -6.546],
                            [-30.659, -6.542],
                            [-31.221, -6.297],
                            [-31.648, -5.854],
                            [-31.901, -5.243]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 14,
                      ty: 'sh',
                      ix: 15,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-38.295, -11.498],
                            [-31.487, -10.175],
                            [-31.733, -8.903],
                            [-34.408, -9.422],
                            [-35.795, -2.284],
                            [-37.268, -2.57],
                            [-35.88, -9.708],
                            [-38.543, -10.226]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'mm',
                      mm: 1,
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.086274509804, 0.086274509804, 0.090196078431, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [80.687, 78.964], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 19,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.168, -0.422],
                            [0, 0],
                            [0.422, -2.168],
                            [0, 0],
                            [2.168, 0.422],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.169, 0.421],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0],
                            [-2.169, -0.422],
                            [0, 0],
                            [0.422, -2.168]
                          ],
                          v: [
                            [-46.28, -22.467],
                            [51.326, -3.494],
                            [54.489, 1.195],
                            [50.969, 19.304],
                            [46.28, 22.467],
                            [-51.326, 3.495],
                            [-54.49, -1.195],
                            [-50.97, -19.304]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.690196078431, 0.854902020623, 0.972549079446, 1],
                        ix: 3
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0.5, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [77.908, 78.295], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.168, -0.422],
                            [0, 0],
                            [0.422, -2.168],
                            [0, 0],
                            [2.168, 0.422],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.169, 0.421],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0],
                            [-2.169, -0.422],
                            [0, 0],
                            [0.422, -2.168]
                          ],
                          v: [
                            [-46.28, -22.467],
                            [51.326, -3.494],
                            [54.489, 1.195],
                            [50.969, 19.304],
                            [46.28, 22.467],
                            [-51.326, 3.495],
                            [-54.49, -1.195],
                            [-50.97, -19.304]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.690196078431, 0.854902020623, 0.972549079446, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [77.908, 78.295], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0.04, 0.195],
                            [0, 0],
                            [-0.15, -0.084],
                            [0, 0],
                            [0.199, -0.013],
                            [0, 0],
                            [0.028, -0.072],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [-0.034, -0.167],
                            [0, 0],
                            [0.174, 0.097],
                            [0, 0],
                            [-0.078, 0.005],
                            [0, 0],
                            [-0.073, 0.186]
                          ],
                          v: [
                            [-4.132, 8.119],
                            [-7.406, -8.04],
                            [-7.111, -8.254],
                            [7.266, -0.185],
                            [7.181, 0.189],
                            [-0.692, 0.685],
                            [-0.865, 0.811],
                            [-3.75, 8.152]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [7.689, 8.588], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  hd: false
                }
              ],
              ip: 0,
              op: 547.2,
              st: -16.8,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 2,
              ty: 2,
              cl: 'png',
              parent: 1,
              tt: 1,
              refId: 'image_1',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 12, ix: 10 },
                p: { a: 0, k: [87.98, 37.532, 0], ix: 2, l: 2 },
                a: { a: 0, k: [80.5, 80.5, 0], ix: 1, l: 2 },
                s: { a: 0, k: [27, 27, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ip: 0,
              op: 547.2,
              st: -16.8,
              bm: 0
            },
            {
              ddd: 0,
              ind: 3,
              ty: 4,
              parent: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 2.4,
                      s: [0]
                    },
                    { t: 16.8, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [88.76, 34.333, 0], ix: 2, l: 2 },
                a: { a: 0, k: [88.76, 34.333, 0], ix: 1, l: 2 },
                s: { a: 0, k: [145, 145, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-10.52, -2.023],
                            [0, 0],
                            [2.132, -10.499],
                            [0, 0],
                            [10.52, 2.023],
                            [-2.132, 10.499],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [10.52, 2.023],
                            [0, 0],
                            [-2.132, 10.499],
                            [-10.52, -2.023],
                            [0, 0],
                            [2.131, -10.499]
                          ],
                          v: [
                            [3.871, -19.067],
                            [3.871, -19.067],
                            [19.06, 3.607],
                            [19.038, 3.719],
                            [-3.871, 19.067],
                            [-19.06, -3.607],
                            [-19.037, -3.719]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'gr',
                      it: [
                        {
                          ty: 'tr',
                          p: { a: 0, k: [0, 0], ix: 2 },
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          r: { a: 0, k: 0, ix: 6 },
                          o: { a: 0, k: 100, ix: 7 },
                          sk: { a: 0, k: 0, ix: 4 },
                          sa: { a: 0, k: 0, ix: 5 }
                        }
                      ],
                      np: 1,
                      cix: 2,
                      bm: 0,
                      ix: 2,
                      hd: false
                    },
                    {
                      ty: 'st',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0.7, ix: 5 },
                      lc: 2,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      d: [
                        {
                          n: 'd',
                          v: { a: 0, k: 5, ix: 1 }
                        },
                        {
                          n: 'o',
                          v: { a: 0, k: 0, ix: 7 }
                        }
                      ],
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [88.76, 34.333], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: 0,
              op: 547.2,
              st: -16.8,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 2.4,
                      s: [0]
                    },
                    { t: 16.8, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -14.4,
                      s: [14]
                    },
                    { t: 32.4, s: [-12] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -14.4,
                      s: [1512.171, 698.659, 0],
                      to: [-29.639, -30.354, 0],
                      ti: [117.826, -88.42, 0]
                    },
                    { t: 32.4, s: [1252.579, 680.137, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [67, 51, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-10.52, -2.023],
                            [0, 0],
                            [2.132, -10.499],
                            [0, 0],
                            [10.52, 2.023],
                            [-2.132, 10.499],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [10.52, 2.023],
                            [0, 0],
                            [-2.132, 10.499],
                            [-10.52, -2.023],
                            [0, 0],
                            [2.131, -10.499]
                          ],
                          v: [
                            [3.871, -19.067],
                            [3.871, -19.067],
                            [19.06, 3.607],
                            [19.038, 3.719],
                            [-3.871, 19.067],
                            [-19.06, -3.607],
                            [-19.037, -3.719]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.694117647059, 0.678431372549, 0.890196078431, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [88.76, 34.333], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: 0,
              op: 547.2,
              st: -16.8,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 4.8,
                      s: [0]
                    },
                    { t: 19.2, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -12,
                      s: [14]
                    },
                    { t: 34.8, s: [-12] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -12,
                      s: [1512.171, 698.659, 0],
                      to: [-43.58, -27.566, 0],
                      ti: [126.191, -45.203, 0]
                    },
                    { t: 34.8, s: [1252.579, 691.137, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [67, 51, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.14, 0.136],
                            [-0.182, 0.095],
                            [-0.211, 0.041],
                            [-0.204, -0.039],
                            [-0.054, -0.018],
                            [-0.062, -0.02],
                            [0, 0],
                            [0.101, 0.028],
                            [0.094, 0.018],
                            [0.222, -0.054],
                            [0.184, -0.152],
                            [0.137, -0.242],
                            [0.065, -0.337],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.072, -0.158],
                            [0.148, -0.134],
                            [0.191, -0.101],
                            [0.212, -0.04],
                            [0.157, 0.031],
                            [0.061, 0.02],
                            [0, 0],
                            [-0.091, -0.034],
                            [-0.091, -0.034],
                            [-0.228, -0.044],
                            [-0.213, 0.049],
                            [-0.183, 0.144],
                            [-0.136, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [34.948, 5.147],
                            [36.209, 5.392],
                            [35.98, 6.57],
                            [36.003, 6.575],
                            [36.32, 6.135],
                            [36.815, 5.791],
                            [37.419, 5.578],
                            [38.043, 5.577],
                            [38.358, 5.651],
                            [38.543, 5.711],
                            [38.29, 7.007],
                            [38.003, 6.914],
                            [37.725, 6.836],
                            [37.049, 6.851],
                            [36.454, 7.151],
                            [35.973, 7.73],
                            [35.67, 8.6],
                            [35.107, 11.498],
                            [33.764, 11.237]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.02, -0.233],
                            [-0.101, -0.191],
                            [-0.184, -0.142],
                            [-0.283, -0.055],
                            [-0.267, 0.12],
                            [-0.183, 0.315],
                            [0, 0],
                            [0.223, -0.226],
                            [0.278, -0.133],
                            [0.323, -0.027],
                            [0.33, 0.064],
                            [0.339, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.418],
                            [-0.091, 0.472],
                            [-0.209, 0.359],
                            [-0.308, 0.242],
                            [-0.395, 0.103],
                            [-0.455, -0.089],
                            [-0.337, -0.278],
                            [-0.188, -0.379],
                            [-0.026, -0.454],
                            [0.144, -0.445]
                          ],
                          o: [
                            [-0.046, 0.236],
                            [0.028, 0.233],
                            [0.102, 0.191],
                            [0.186, 0.134],
                            [0.393, 0.076],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.135, 0.316],
                            [-0.223, 0.225],
                            [-0.277, 0.126],
                            [-0.317, 0.036],
                            [-0.479, -0.093],
                            [-0.338, -0.229],
                            [-0.196, -0.332],
                            [-0.049, -0.417],
                            [0.084, -0.432],
                            [0.217, -0.365],
                            [0.317, -0.248],
                            [0.395, -0.103],
                            [0.479, 0.093],
                            [0.346, 0.271],
                            [0.187, 0.378],
                            [0.036, 0.446],
                            [0, 0]
                          ],
                          v: [
                            [28.792, 7.471],
                            [28.753, 8.173],
                            [28.946, 8.809],
                            [29.376, 9.308],
                            [30.079, 9.592],
                            [31.069, 9.527],
                            [31.759, 8.867],
                            [33.032, 9.114],
                            [32.495, 9.927],
                            [31.742, 10.465],
                            [30.842, 10.694],
                            [29.872, 10.652],
                            [28.646, 10.169],
                            [27.831, 9.326],
                            [27.451, 8.201],
                            [27.514, 6.868],
                            [27.954, 5.682],
                            [28.742, 4.772],
                            [29.81, 4.246],
                            [31.085, 4.225],
                            [32.31, 4.781],
                            [33.111, 5.756],
                            [33.43, 7.004],
                            [33.268, 8.341]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.04, 0.211],
                            [0.106, 0.167],
                            [0.172, 0.124],
                            [0.228, 0.044],
                            [0.213, -0.048],
                            [0.17, -0.121],
                            [0.124, -0.18],
                            [0.052, -0.227]
                          ],
                          o: [
                            [0.025, -0.215],
                            [-0.033, -0.21],
                            [-0.097, -0.174],
                            [-0.162, -0.129],
                            [-0.235, -0.046],
                            [-0.204, 0.042],
                            [-0.161, 0.116],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [32.096, 7.197],
                            [32.075, 6.557],
                            [31.867, 5.991],
                            [31.464, 5.545],
                            [30.878, 5.285],
                            [30.206, 5.289],
                            [29.644, 5.534],
                            [29.216, 5.977],
                            [28.964, 6.588]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.052, 0.267],
                            [0.02, 0.273],
                            [0.103, 0.224],
                            [0.189, 0.159],
                            [0.275, 0.053],
                            [0.367, -0.336],
                            [0.127, -0.652],
                            [-0.019, -0.281],
                            [-0.113, -0.218],
                            [-0.183, -0.15],
                            [-0.275, -0.054],
                            [-0.236, 0.085],
                            [-0.171, 0.17],
                            [-0.104, 0.241]
                          ],
                          o: [
                            [0.054, -0.275],
                            [-0.02, -0.272],
                            [-0.102, -0.231],
                            [-0.188, -0.166],
                            [-0.566, -0.11],
                            [-0.359, 0.338],
                            [-0.06, 0.306],
                            [0.028, 0.275],
                            [0.112, 0.217],
                            [0.191, 0.151],
                            [0.306, 0.059],
                            [0.237, -0.084],
                            [0.181, -0.177],
                            [0.113, -0.247]
                          ],
                          v: [
                            [25.331, 6.444],
                            [25.382, 5.622],
                            [25.196, 4.877],
                            [24.761, 4.291],
                            [24.066, 3.961],
                            [22.668, 4.3],
                            [21.94, 5.785],
                            [21.878, 6.665],
                            [22.09, 7.403],
                            [22.533, 7.954],
                            [23.231, 8.261],
                            [24.044, 8.223],
                            [24.657, 7.841],
                            [25.085, 7.215]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 4,
                      ty: 'sh',
                      ix: 5,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.157, 0.091],
                            [-0.173, 0.048],
                            [-0.181, 0.005],
                            [-0.173, -0.033],
                            [-0.32, -0.241],
                            [-0.178, -0.344],
                            [-0.042, -0.416],
                            [0.087, -0.448],
                            [0.185, -0.363],
                            [0.278, -0.255],
                            [0.365, -0.116],
                            [0.447, 0.087],
                            [0.206, 0.073],
                            [0.173, 0.116],
                            [0.133, 0.157],
                            [0.062, 0.224],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.123, -0.131],
                            [0.156, -0.092],
                            [0.181, -0.055],
                            [0.19, -0.012],
                            [0.479, 0.093],
                            [0.329, 0.235],
                            [0.179, 0.336],
                            [0.041, 0.415],
                            [-0.08, 0.408],
                            [-0.176, 0.365],
                            [-0.268, 0.25],
                            [-0.363, 0.109],
                            [-0.205, -0.04],
                            [-0.199, -0.063],
                            [-0.173, -0.115],
                            [-0.124, -0.154],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [21.688, 0.161],
                            [23.03, 0.422],
                            [22.426, 3.532],
                            [22.45, 3.537],
                            [22.87, 3.203],
                            [23.362, 2.993],
                            [23.905, 2.903],
                            [24.45, 2.935],
                            [25.648, 3.437],
                            [26.408, 4.306],
                            [26.738, 5.434],
                            [26.67, 6.729],
                            [26.273, 7.886],
                            [25.591, 8.817],
                            [24.642, 9.366],
                            [23.425, 9.399],
                            [22.81, 9.23],
                            [22.251, 8.962],
                            [21.792, 8.555],
                            [21.512, 7.987],
                            [21.487, 7.983],
                            [21.326, 8.819],
                            [20.053, 8.572]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 5,
                      ty: 'sh',
                      ix: 6,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.142, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.01],
                            [-0.244, -0.047],
                            [-0.282, -0.226],
                            [-0.066, -0.371],
                            [-0.333, 0.123],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.739],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.2],
                            [0.051, 0.156],
                            [0.134, 0.107],
                            [0.259, 0.05],
                            [0.259, -0.243],
                            [0.101, -0.519],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.243],
                            [0.408, 0.079],
                            [0.179, -0.038],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.251],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.129],
                            [0.142, -0.103],
                            [0.166, -0.057],
                            [0.199, -0.019],
                            [0.369, 0.072],
                            [0.29, 0.227],
                            [0.289, -0.27],
                            [0.334, -0.122],
                            [0.644, 0.125],
                            [0.301, 0.384],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.244],
                            [0.024, -0.207],
                            [-0.042, -0.163],
                            [-0.134, -0.108],
                            [-0.456, -0.089],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.249],
                            [-0.173, -0.034],
                            [-0.171, 0.04],
                            [-0.143, 0.111],
                            [-0.109, 0.182],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [10.951, 0.483],
                            [12.223, 0.73],
                            [12.06, 1.578],
                            [12.095, 1.585],
                            [12.504, 1.237],
                            [12.955, 0.994],
                            [13.501, 0.893],
                            [14.164, 0.936],
                            [15.141, 1.383],
                            [15.675, 2.281],
                            [16.608, 1.692],
                            [17.78, 1.639],
                            [19.185, 2.401],
                            [19.419, 4.085],
                            [18.602, 8.29],
                            [17.259, 8.029],
                            [17.951, 4.472],
                            [18.055, 3.807],
                            [18.016, 3.262],
                            [17.752, 2.856],
                            [17.163, 2.619],
                            [16.091, 2.851],
                            [15.55, 3.993],
                            [14.857, 7.562],
                            [13.513, 7.301],
                            [14.273, 3.39],
                            [14.223, 2.39],
                            [13.451, 1.898],
                            [12.924, 1.905],
                            [12.44, 2.129],
                            [12.05, 2.567],
                            [11.814, 3.217],
                            [11.111, 6.834],
                            [9.768, 6.573]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 6,
                      ty: 'sh',
                      ix: 7,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.02, -0.232],
                            [-0.101, -0.191],
                            [-0.185, -0.142],
                            [-0.283, -0.055],
                            [-0.268, 0.119],
                            [-0.184, 0.314],
                            [0, 0],
                            [0.223, -0.225],
                            [0.278, -0.134],
                            [0.323, -0.027],
                            [0.329, 0.064],
                            [0.339, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.419],
                            [-0.091, 0.471],
                            [-0.209, 0.359],
                            [-0.308, 0.241],
                            [-0.395, 0.102],
                            [-0.455, -0.089],
                            [-0.338, -0.277],
                            [-0.187, -0.378],
                            [-0.026, -0.453],
                            [0.143, -0.445]
                          ],
                          o: [
                            [-0.046, 0.235],
                            [0.028, 0.234],
                            [0.102, 0.191],
                            [0.186, 0.134],
                            [0.392, 0.077],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.135, 0.316],
                            [-0.223, 0.226],
                            [-0.277, 0.125],
                            [-0.316, 0.036],
                            [-0.479, -0.093],
                            [-0.338, -0.228],
                            [-0.196, -0.332],
                            [-0.05, -0.417],
                            [0.084, -0.432],
                            [0.217, -0.365],
                            [0.317, -0.249],
                            [0.395, -0.103],
                            [0.479, 0.093],
                            [0.346, 0.272],
                            [0.188, 0.379],
                            [0.035, 0.447],
                            [0, 0]
                          ],
                          v: [
                            [4.795, 2.807],
                            [4.757, 3.508],
                            [4.951, 4.145],
                            [5.379, 4.644],
                            [6.082, 4.927],
                            [7.073, 4.863],
                            [7.763, 4.203],
                            [9.035, 4.45],
                            [8.498, 5.262],
                            [7.747, 5.801],
                            [6.846, 6.029],
                            [5.877, 5.987],
                            [4.649, 5.504],
                            [3.836, 4.662],
                            [3.456, 3.536],
                            [3.518, 2.204],
                            [3.957, 1.018],
                            [4.746, 0.108],
                            [5.814, -0.418],
                            [7.089, -0.439],
                            [8.314, 0.116],
                            [9.113, 1.091],
                            [9.434, 2.339],
                            [9.272, 3.677]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 7,
                      ty: 'sh',
                      ix: 8,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.041, 0.212],
                            [0.106, 0.167],
                            [0.172, 0.123],
                            [0.228, 0.044],
                            [0.213, -0.048],
                            [0.17, -0.122],
                            [0.124, -0.179],
                            [0.052, -0.226]
                          ],
                          o: [
                            [0.025, -0.215],
                            [-0.032, -0.21],
                            [-0.097, -0.173],
                            [-0.162, -0.129],
                            [-0.236, -0.046],
                            [-0.204, 0.042],
                            [-0.161, 0.115],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [8.101, 2.532],
                            [8.077, 1.892],
                            [7.871, 1.326],
                            [7.467, 0.881],
                            [6.883, 0.621],
                            [6.21, 0.624],
                            [5.648, 0.87],
                            [5.221, 1.312],
                            [4.966, 1.923]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 8,
                      ty: 'sh',
                      ix: 9,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.142, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.01],
                            [-0.244, -0.047],
                            [-0.282, -0.226],
                            [-0.067, -0.371],
                            [-0.333, 0.122],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.738],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.199],
                            [0.051, 0.157],
                            [0.134, 0.108],
                            [0.259, 0.051],
                            [0.259, -0.243],
                            [0.101, -0.518],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.243],
                            [0.408, 0.079],
                            [0.179, -0.039],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.252],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.129],
                            [0.142, -0.103],
                            [0.166, -0.058],
                            [0.199, -0.018],
                            [0.369, 0.072],
                            [0.29, 0.227],
                            [0.289, -0.269],
                            [0.334, -0.123],
                            [0.644, 0.125],
                            [0.301, 0.385],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.244],
                            [0.024, -0.208],
                            [-0.042, -0.163],
                            [-0.134, -0.107],
                            [-0.456, -0.088],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.248],
                            [-0.173, -0.034],
                            [-0.171, 0.04],
                            [-0.143, 0.111],
                            [-0.109, 0.183],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-5.843, -2.782],
                            [-4.571, -2.535],
                            [-4.737, -1.687],
                            [-4.701, -1.68],
                            [-4.29, -2.028],
                            [-3.841, -2.27],
                            [-3.294, -2.372],
                            [-2.63, -2.329],
                            [-1.654, -1.882],
                            [-1.119, -0.984],
                            [-0.186, -1.572],
                            [0.986, -1.626],
                            [2.389, -0.864],
                            [2.624, 0.82],
                            [1.808, 5.025],
                            [0.465, 4.764],
                            [1.157, 1.207],
                            [1.261, 0.543],
                            [1.221, -0.003],
                            [0.956, -0.409],
                            [0.367, -0.646],
                            [-0.705, -0.414],
                            [-1.244, 0.728],
                            [-1.939, 4.297],
                            [-3.281, 4.036],
                            [-2.522, 0.125],
                            [-2.571, -0.875],
                            [-3.343, -1.367],
                            [-3.871, -1.359],
                            [-4.354, -1.136],
                            [-4.744, -0.698],
                            [-4.982, -0.047],
                            [-5.685, 3.569],
                            [-7.028, 3.308]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 9,
                      ty: 'sh',
                      ix: 10,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.143, 0.103],
                            [-0.158, 0.059],
                            [-0.198, 0.011],
                            [-0.244, -0.048],
                            [-0.282, -0.226],
                            [-0.067, -0.372],
                            [-0.333, 0.123],
                            [-0.448, -0.087],
                            [-0.292, -0.383],
                            [0.144, -0.738],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.022, 0.199],
                            [0.051, 0.157],
                            [0.134, 0.107],
                            [0.259, 0.05],
                            [0.259, -0.243],
                            [0.101, -0.518],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.116, 0.242],
                            [0.408, 0.08],
                            [0.179, -0.039],
                            [0.152, -0.109],
                            [0.116, -0.181],
                            [0.049, -0.251],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.131, -0.13],
                            [0.142, -0.102],
                            [0.166, -0.057],
                            [0.199, -0.018],
                            [0.369, 0.072],
                            [0.29, 0.228],
                            [0.289, -0.27],
                            [0.334, -0.122],
                            [0.644, 0.125],
                            [0.301, 0.384],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.047, -0.243],
                            [0.024, -0.207],
                            [-0.042, -0.163],
                            [-0.134, -0.108],
                            [-0.456, -0.089],
                            [-0.259, 0.243],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.083, -0.424],
                            [-0.106, -0.249],
                            [-0.173, -0.033],
                            [-0.171, 0.04],
                            [-0.143, 0.11],
                            [-0.109, 0.183],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-19.372, -5.412],
                            [-18.1, -5.164],
                            [-18.264, -4.316],
                            [-18.228, -4.309],
                            [-17.819, -4.658],
                            [-17.368, -4.9],
                            [-16.822, -5.002],
                            [-16.159, -4.958],
                            [-15.182, -4.512],
                            [-14.648, -3.613],
                            [-13.715, -4.202],
                            [-12.543, -4.255],
                            [-11.138, -3.493],
                            [-10.904, -1.81],
                            [-11.721, 2.396],
                            [-13.064, 2.135],
                            [-12.372, -1.423],
                            [-12.268, -2.087],
                            [-12.307, -2.633],
                            [-12.571, -3.038],
                            [-13.16, -3.275],
                            [-14.232, -3.044],
                            [-14.773, -1.902],
                            [-15.466, 1.668],
                            [-16.81, 1.407],
                            [-16.049, -2.504],
                            [-16.1, -3.504],
                            [-16.872, -3.997],
                            [-17.398, -3.989],
                            [-17.883, -3.765],
                            [-18.273, -3.328],
                            [-18.509, -2.677],
                            [-19.212, 0.94],
                            [-20.555, 0.678]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 10,
                      ty: 'sh',
                      ix: 11,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.304, 0.026],
                            [0.31, 0.003],
                            [0.142, -0.021],
                            [0.117, -0.059],
                            [0.087, -0.114],
                            [0.034, -0.172],
                            [-0.066, -0.119],
                            [-0.105, -0.085],
                            [-0.137, -0.051],
                            [-0.118, -0.023],
                            [-0.18, 0.006],
                            [-0.167, 0.065],
                            [-0.129, 0.122],
                            [-0.041, 0.212]
                          ],
                          o: [
                            [-0.23, 0.094],
                            [-0.303, -0.035],
                            [-0.146, -0.004],
                            [-0.142, 0.013],
                            [-0.116, 0.051],
                            [-0.078, 0.107],
                            [-0.029, 0.15],
                            [0.067, 0.118],
                            [0.115, 0.08],
                            [0.145, 0.053],
                            [0.149, 0.029],
                            [0.181, -0.005],
                            [0.176, -0.064],
                            [0.132, -0.129],
                            [0, 0]
                          ],
                          v: [
                            [-22.749, -2.914],
                            [-23.551, -2.813],
                            [-24.471, -2.87],
                            [-24.903, -2.844],
                            [-25.29, -2.736],
                            [-25.596, -2.489],
                            [-25.763, -2.07],
                            [-25.706, -1.667],
                            [-25.449, -1.361],
                            [-25.07, -1.165],
                            [-24.676, -1.052],
                            [-24.182, -1.017],
                            [-23.66, -1.123],
                            [-23.203, -1.401],
                            [-22.945, -1.913]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 11,
                      ty: 'sh',
                      ix: 12,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.026, -0.079],
                            [-0.125, -0.024],
                            [-0.055, -0.011],
                            [-0.074, 0.002],
                            [0, 0],
                            [0.082, -0.001],
                            [0.082, 0],
                            [0.08, 0.007],
                            [0.055, 0.01],
                            [0.159, 0.145],
                            [0.001, 0.286],
                            [0.416, -0.041],
                            [0.361, 0.07],
                            [0.236, 0.127],
                            [0.169, 0.18],
                            [0.075, 0.242],
                            [-0.058, 0.299],
                            [-0.18, 0.21],
                            [-0.246, 0.091],
                            [-0.286, 0.001],
                            [-0.288, -0.023],
                            [-0.223, -0.027],
                            [-0.174, 0.016],
                            [-0.112, 0.076],
                            [-0.038, 0.197],
                            [0.065, 0.127],
                            [0.115, 0.08],
                            [0.146, 0.044],
                            [0.134, 0.026],
                            [0.274, -0.11],
                            [0.095, -0.324],
                            [0, 0],
                            [-0.216, 0.227],
                            [-0.282, 0.108],
                            [-0.334, 0],
                            [-0.338, -0.066],
                            [-0.278, -0.12],
                            [-0.2, -0.186],
                            [-0.097, -0.256],
                            [0.062, -0.322]
                          ],
                          o: [
                            [-0.032, 0.165],
                            [0.033, 0.08],
                            [0.04, 0.008],
                            [0.055, 0.01],
                            [0, 0],
                            [-0.05, 0.007],
                            [-0.075, 0.01],
                            [-0.082, 0.001],
                            [-0.08, -0.008],
                            [-0.275, -0.054],
                            [-0.159, -0.145],
                            [-0.318, 0.207],
                            [-0.407, 0.043],
                            [-0.275, -0.053],
                            [-0.238, -0.119],
                            [-0.159, -0.185],
                            [-0.065, -0.25],
                            [0.073, -0.377],
                            [0.187, -0.208],
                            [0.254, -0.089],
                            [0.295, -0.008],
                            [0.253, 0.001],
                            [0.224, 0.019],
                            [0.182, -0.013],
                            [0.122, -0.082],
                            [0.034, -0.172],
                            [-0.057, -0.125],
                            [-0.105, -0.085],
                            [-0.145, -0.053],
                            [-0.377, -0.073],
                            [-0.274, 0.109],
                            [0, 0],
                            [0.1, -0.388],
                            [0.215, -0.227],
                            [0.29, -0.107],
                            [0.334, -0.001],
                            [0.298, 0.058],
                            [0.278, 0.119],
                            [0.208, 0.187],
                            [0.098, 0.247],
                            [0, 0]
                          ],
                          v: [
                            [-21.737, -0.957],
                            [-21.747, -0.592],
                            [-21.508, -0.436],
                            [-21.367, -0.408],
                            [-21.174, -0.395],
                            [-21.354, 0.535],
                            [-21.552, 0.546],
                            [-21.788, 0.561],
                            [-22.029, 0.551],
                            [-22.232, 0.524],
                            [-22.883, 0.226],
                            [-23.124, -0.42],
                            [-24.225, -0.047],
                            [-25.378, -0.088],
                            [-26.143, -0.359],
                            [-26.753, -0.808],
                            [-27.106, -1.45],
                            [-27.117, -2.272],
                            [-26.737, -3.152],
                            [-26.088, -3.6],
                            [-25.279, -3.736],
                            [-24.404, -3.713],
                            [-23.69, -3.672],
                            [-23.092, -3.666],
                            [-22.651, -3.8],
                            [-22.411, -4.218],
                            [-22.458, -4.667],
                            [-22.716, -4.974],
                            [-23.093, -5.169],
                            [-23.512, -5.287],
                            [-24.487, -5.232],
                            [-25.041, -4.582],
                            [-26.384, -4.843],
                            [-25.911, -5.766],
                            [-25.166, -6.269],
                            [-24.231, -6.429],
                            [-23.222, -6.331],
                            [-22.357, -6.065],
                            [-21.639, -5.608],
                            [-21.181, -4.944],
                            [-21.128, -4.09]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 12,
                      ty: 'sh',
                      ix: 13,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.021, -0.232],
                            [-0.102, -0.19],
                            [-0.184, -0.141],
                            [-0.283, -0.055],
                            [-0.268, 0.12],
                            [-0.183, 0.315],
                            [0, 0],
                            [0.223, -0.226],
                            [0.279, -0.133],
                            [0.323, -0.027],
                            [0.33, 0.064],
                            [0.338, 0.229],
                            [0.204, 0.333],
                            [0.057, 0.418],
                            [-0.092, 0.471],
                            [-0.208, 0.359],
                            [-0.308, 0.242],
                            [-0.395, 0.103],
                            [-0.456, -0.088],
                            [-0.337, -0.277],
                            [-0.187, -0.379],
                            [-0.026, -0.454],
                            [0.144, -0.445]
                          ],
                          o: [
                            [-0.046, 0.236],
                            [0.028, 0.233],
                            [0.101, 0.191],
                            [0.186, 0.134],
                            [0.393, 0.076],
                            [0.277, -0.125],
                            [0, 0],
                            [-0.134, 0.317],
                            [-0.224, 0.225],
                            [-0.277, 0.126],
                            [-0.317, 0.036],
                            [-0.479, -0.093],
                            [-0.339, -0.229],
                            [-0.197, -0.331],
                            [-0.049, -0.417],
                            [0.083, -0.433],
                            [0.218, -0.365],
                            [0.317, -0.248],
                            [0.395, -0.102],
                            [0.479, 0.093],
                            [0.347, 0.271],
                            [0.187, 0.378],
                            [0.035, 0.447],
                            [0, 0]
                          ],
                          v: [
                            [-32.074, -4.36],
                            [-32.112, -3.658],
                            [-31.918, -3.022],
                            [-31.489, -2.523],
                            [-30.786, -2.239],
                            [-29.795, -2.304],
                            [-29.106, -2.964],
                            [-27.833, -2.717],
                            [-28.369, -1.904],
                            [-29.122, -1.366],
                            [-30.023, -1.137],
                            [-30.992, -1.179],
                            [-32.218, -1.662],
                            [-33.033, -2.505],
                            [-33.414, -3.63],
                            [-33.349, -4.962],
                            [-32.911, -6.149],
                            [-32.123, -7.059],
                            [-31.055, -7.585],
                            [-29.779, -7.606],
                            [-28.555, -7.05],
                            [-27.754, -6.075],
                            [-27.435, -4.827],
                            [-27.597, -3.49]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 13,
                      ty: 'sh',
                      ix: 14,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.04, 0.211],
                            [0.106, 0.167],
                            [0.171, 0.123],
                            [0.227, 0.045],
                            [0.214, -0.048],
                            [0.171, -0.121],
                            [0.125, -0.18],
                            [0.052, -0.227]
                          ],
                          o: [
                            [0.026, -0.215],
                            [-0.033, -0.21],
                            [-0.097, -0.174],
                            [-0.163, -0.13],
                            [-0.236, -0.046],
                            [-0.203, 0.042],
                            [-0.161, 0.116],
                            [-0.117, 0.181],
                            [0, 0]
                          ],
                          v: [
                            [-28.768, -4.634],
                            [-28.79, -5.274],
                            [-28.998, -5.84],
                            [-29.4, -6.285],
                            [-29.986, -6.546],
                            [-30.659, -6.542],
                            [-31.221, -6.297],
                            [-31.648, -5.854],
                            [-31.901, -5.243]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 14,
                      ty: 'sh',
                      ix: 15,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-38.295, -11.498],
                            [-31.487, -10.175],
                            [-31.733, -8.903],
                            [-34.408, -9.422],
                            [-35.795, -2.284],
                            [-37.268, -2.57],
                            [-35.88, -9.708],
                            [-38.543, -10.226]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'mm',
                      mm: 1,
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.086274509804, 0.086274509804, 0.090196078431, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [80.687, 78.964], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [-100, 100], ix: 3 },
                      r: { a: 0, k: 23, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 19,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.168, -0.422],
                            [0, 0],
                            [0.422, -2.168],
                            [0, 0],
                            [2.168, 0.422],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.169, 0.421],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0],
                            [-2.169, -0.422],
                            [0, 0],
                            [0.422, -2.168]
                          ],
                          v: [
                            [-46.28, -22.467],
                            [51.326, -3.494],
                            [54.489, 1.195],
                            [50.969, 19.304],
                            [46.28, 22.467],
                            [-51.326, 3.495],
                            [-54.49, -1.195],
                            [-50.97, -19.304]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.694117647059, 0.678431372549, 0.890196078431, 1],
                        ix: 3
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0.5, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [77.908, 78.295], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.168, -0.422],
                            [0, 0],
                            [0.422, -2.168],
                            [0, 0],
                            [2.168, 0.422],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.169, 0.421],
                            [0, 0],
                            [-0.421, 2.169],
                            [0, 0],
                            [-2.169, -0.422],
                            [0, 0],
                            [0.422, -2.168]
                          ],
                          v: [
                            [-46.28, -22.467],
                            [51.326, -3.494],
                            [54.489, 1.195],
                            [50.969, 19.304],
                            [46.28, 22.467],
                            [-51.326, 3.495],
                            [-54.49, -1.195],
                            [-50.97, -19.304]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.749019607843, 0.772549019608, 0.956862745098, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [77.908, 78.295], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  hd: false
                }
              ],
              ip: 0,
              op: 547.2,
              st: -14.4,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 6,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 0,
                      s: [0]
                    },
                    { t: 14.4, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -16.8,
                      s: [14]
                    },
                    { t: 30, s: [-12] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -16.8,
                      s: [1512.171, 698.659, 0],
                      to: [-29.639, -30.354, 0],
                      ti: [117.826, -88.42, 0]
                    },
                    { t: 30, s: [1272.579, 653.137, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [67, 51, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0.04, 0.195],
                            [0, 0],
                            [-0.15, -0.084],
                            [0, 0],
                            [0.199, -0.013],
                            [0, 0],
                            [0.028, -0.072],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [-0.034, -0.167],
                            [0, 0],
                            [0.174, 0.097],
                            [0, 0],
                            [-0.078, 0.005],
                            [0, 0],
                            [-0.073, 0.186]
                          ],
                          v: [
                            [-4.132, 8.119],
                            [-7.406, -8.04],
                            [-7.111, -8.254],
                            [7.266, -0.185],
                            [7.181, 0.189],
                            [-0.692, 0.685],
                            [-0.865, 0.811],
                            [-3.75, 8.152]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [7.689, 8.588], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  hd: false
                }
              ],
              ip: 0,
              op: 547.2,
              st: -19.2,
              ct: 1,
              bm: 0
            }
          ]
        },
        {
          id: 'comp_1',
          fr: 25,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 0,
                      s: [0]
                    },
                    { t: 6, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -16.8,
                      s: [25]
                    },
                    { t: 30, s: [43] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -16.8,
                      s: [37.659, 111.882, 0],
                      to: [142.92, 42.625, 0],
                      ti: [-7.333, 19.833, 0]
                    },
                    { t: 30, s: [271.716, 102.034, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [62.461, 43.062, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.174, 0.096],
                            [0, 0],
                            [0.036, -0.168],
                            [0, 0],
                            [0.072, 0.186],
                            [0, 0],
                            [0.078, 0.006],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.15, -0.083],
                            [0, 0],
                            [-0.04, 0.195],
                            [0, 0],
                            [-0.028, -0.072],
                            [0, 0],
                            [-0.199, -0.014]
                          ],
                          v: [
                            [-7.292, -0.269],
                            [7.138, -8.243],
                            [7.43, -8.027],
                            [4.052, 8.11],
                            [3.67, 8.141],
                            [0.833, 0.78],
                            [0.66, 0.652],
                            [-7.209, 0.106]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [117.206, 8.577], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 26, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  hd: false
                }
              ],
              ip: 0,
              op: 85.2,
              st: -16.8,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 2,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 4.8,
                      s: [0]
                    },
                    { t: 10.8, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -12,
                      s: [25]
                    },
                    { t: 34.8, s: [0] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -12,
                      s: [37.659, 111.882, 0],
                      to: [142.92, 42.625, 0],
                      ti: [-7.333, 19.833, 0]
                    },
                    { t: 34.8, s: [258.216, 76.034, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [62.461, 43.062, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-10.843, 2.108],
                            [0, 0],
                            [-2.108, -10.842],
                            [10.843, -2.108],
                            [2.107, 10.842]
                          ],
                          o: [
                            [0, 0],
                            [10.843, -2.108],
                            [2.107, 10.843],
                            [-10.842, 2.108],
                            [-2.108, -10.843]
                          ],
                          v: [
                            [-3.816, -19.633],
                            [-3.816, -19.633],
                            [19.633, -3.816],
                            [3.816, 19.632],
                            [-19.632, 3.816]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [36.769, 27.214], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.095, 0.171],
                            [-0.147, 0.144],
                            [-0.191, 0.1],
                            [-0.207, 0.021],
                            [-0.056, -0.002],
                            [-0.065, -0.002],
                            [0, 0],
                            [0.105, -0.002],
                            [0.096, -0.009],
                            [0.197, -0.116],
                            [0.133, -0.198],
                            [0.061, -0.272],
                            [-0.035, -0.342],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.023, -0.171],
                            [0.103, -0.171],
                            [0.153, -0.152],
                            [0.191, -0.1],
                            [0.159, -0.015],
                            [0.065, 0.002],
                            [0, 0],
                            [-0.097, -0.006],
                            [-0.097, -0.006],
                            [-0.23, 0.023],
                            [-0.19, 0.108],
                            [-0.133, 0.19],
                            [-0.061, 0.271],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [21.493, -5.167],
                            [22.77, -5.295],
                            [22.889, -4.1],
                            [22.913, -4.103],
                            [23.092, -4.615],
                            [23.467, -5.087],
                            [23.983, -5.464],
                            [24.58, -5.645],
                            [24.903, -5.665],
                            [25.098, -5.66],
                            [25.228, -4.347],
                            [24.926, -4.353],
                            [24.637, -4.348],
                            [23.996, -4.139],
                            [23.51, -3.68],
                            [23.218, -2.988],
                            [23.178, -2.067],
                            [23.473, 0.869],
                            [22.111, 1.007]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.152, -0.153],
                            [-0.217, -0.083],
                            [-0.287, 0.029],
                            [-0.222, 0.191],
                            [-0.085, 0.354],
                            [0, 0],
                            [0.149, -0.281],
                            [0.228, -0.208],
                            [0.302, -0.118],
                            [0.335, -0.033],
                            [0.39, 0.122],
                            [0.291, 0.26],
                            [0.175, 0.384],
                            [0.048, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.349, 0.212],
                            [-0.462, 0.046],
                            [-0.403, -0.169],
                            [-0.288, -0.309],
                            [-0.155, -0.427],
                            [0.01, -0.467]
                          ],
                          o: [
                            [0.024, 0.239],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.217, 0.075],
                            [0.398, -0.04],
                            [0.229, -0.199],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.148, 0.28],
                            [-0.23, 0.199],
                            [-0.293, 0.126],
                            [-0.485, 0.049],
                            [-0.39, -0.122],
                            [-0.284, -0.261],
                            [-0.168, -0.385],
                            [-0.044, -0.438],
                            [0.104, -0.413],
                            [0.233, -0.329],
                            [0.348, -0.212],
                            [0.486, -0.049],
                            [0.41, 0.159],
                            [0.288, 0.308],
                            [0.163, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [16.265, -1.17],
                            [16.431, -0.486],
                            [16.799, 0.067],
                            [17.353, 0.423],
                            [18.108, 0.492],
                            [19.038, 0.145],
                            [19.509, -0.686],
                            [20.799, -0.816],
                            [20.518, 0.118],
                            [19.954, 0.85],
                            [19.156, 1.327],
                            [18.215, 1.566],
                            [16.902, 1.457],
                            [15.88, 0.884],
                            [15.192, -0.084],
                            [14.869, -1.378],
                            [14.947, -2.641],
                            [15.441, -3.74],
                            [16.312, -4.55],
                            [17.527, -4.938],
                            [18.861, -4.757],
                            [19.908, -4.054],
                            [20.572, -2.951],
                            [20.803, -1.624]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.099, 0.191],
                            [0.149, 0.129],
                            [0.2, 0.069],
                            [0.231, -0.023],
                            [0.191, -0.107],
                            [0.128, -0.166],
                            [0.067, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.037, -0.214],
                            [-0.092, -0.192],
                            [-0.143, -0.139],
                            [-0.193, -0.077],
                            [-0.239, 0.024],
                            [-0.183, 0.099],
                            [-0.121, 0.157],
                            [-0.06, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [19.351, -2.382],
                            [19.147, -2.99],
                            [18.785, -3.472],
                            [18.27, -3.782],
                            [17.635, -3.863],
                            [16.991, -3.667],
                            [16.525, -3.269],
                            [16.243, -2.722],
                            [16.176, -2.064]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [5.004, -3.514],
                            [6.449, -3.658],
                            [8.116, 0.83],
                            [8.14, 0.827],
                            [8.837, -3.897],
                            [10.21, -4.035],
                            [11.781, 0.463],
                            [11.806, 0.46],
                            [12.598, -4.274],
                            [13.983, -4.413],
                            [12.666, 1.954],
                            [11.27, 2.093],
                            [9.665, -2.376],
                            [9.64, -2.374],
                            [8.965, 2.325],
                            [7.533, 2.468]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 4,
                      ty: 'sh',
                      ix: 5,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.152, -0.153],
                            [-0.217, -0.083],
                            [-0.287, 0.029],
                            [-0.222, 0.192],
                            [-0.085, 0.355],
                            [0, 0],
                            [0.149, -0.281],
                            [0.228, -0.208],
                            [0.302, -0.118],
                            [0.334, -0.034],
                            [0.39, 0.122],
                            [0.291, 0.261],
                            [0.175, 0.384],
                            [0.048, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.349, 0.212],
                            [-0.462, 0.046],
                            [-0.403, -0.169],
                            [-0.288, -0.309],
                            [-0.156, -0.427],
                            [0.01, -0.468]
                          ],
                          o: [
                            [0.024, 0.238],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.217, 0.075],
                            [0.398, -0.04],
                            [0.229, -0.199],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.148, 0.28],
                            [-0.23, 0.2],
                            [-0.293, 0.126],
                            [-0.486, 0.048],
                            [-0.39, -0.122],
                            [-0.284, -0.261],
                            [-0.168, -0.385],
                            [-0.044, -0.438],
                            [0.104, -0.413],
                            [0.233, -0.329],
                            [0.348, -0.212],
                            [0.486, -0.049],
                            [0.41, 0.16],
                            [0.288, 0.309],
                            [0.162, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [0.337, 0.429],
                            [0.502, 1.111],
                            [0.871, 1.665],
                            [1.426, 2.019],
                            [2.181, 2.088],
                            [3.111, 1.742],
                            [3.582, 0.911],
                            [4.871, 0.782],
                            [4.59, 1.714],
                            [4.025, 2.446],
                            [3.228, 2.924],
                            [2.288, 3.163],
                            [0.975, 3.053],
                            [-0.048, 2.48],
                            [-0.736, 1.512],
                            [-1.059, 0.218],
                            [-0.98, -1.043],
                            [-0.488, -2.142],
                            [0.384, -2.954],
                            [1.6, -3.34],
                            [2.932, -3.16],
                            [3.98, -2.457],
                            [4.645, -1.355],
                            [4.874, -0.027]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 5,
                      ty: 'sh',
                      ix: 6,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.099, 0.191],
                            [0.149, 0.13],
                            [0.2, 0.068],
                            [0.231, -0.023],
                            [0.191, -0.108],
                            [0.128, -0.165],
                            [0.067, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.037, -0.214],
                            [-0.092, -0.192],
                            [-0.143, -0.139],
                            [-0.192, -0.078],
                            [-0.239, 0.024],
                            [-0.183, 0.098],
                            [-0.121, 0.157],
                            [-0.06, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [3.423, -0.785],
                            [3.218, -1.392],
                            [2.856, -1.874],
                            [2.342, -2.184],
                            [1.707, -2.266],
                            [1.064, -2.068],
                            [0.598, -1.673],
                            [0.314, -1.126],
                            [0.247, -0.467]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 6,
                      ty: 'sh',
                      ix: 7,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-3.915, -2.618],
                            [-2.553, -2.756],
                            [-1.934, 3.417],
                            [-3.296, 3.554]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 7,
                      ty: 'sh',
                      ix: 8,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-4.15, -4.972],
                            [-2.79, -5.107],
                            [-2.66, -3.818],
                            [-4.02, -3.681]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 8,
                      ty: 'sh',
                      ix: 9,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-10.751, -1.933],
                            [-9.27, -2.081],
                            [-7.23, 2.502],
                            [-7.207, 2.499],
                            [-6.178, -2.392],
                            [-4.769, -2.533],
                            [-6.358, 3.861],
                            [-7.887, 4.014]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 9,
                      ty: 'sh',
                      ix: 10,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.153, -0.153],
                            [-0.218, -0.083],
                            [-0.286, 0.029],
                            [-0.222, 0.191],
                            [-0.085, 0.354],
                            [0, 0],
                            [0.149, -0.281],
                            [0.229, -0.208],
                            [0.302, -0.118],
                            [0.334, -0.033],
                            [0.39, 0.122],
                            [0.292, 0.26],
                            [0.176, 0.384],
                            [0.047, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.348, 0.212],
                            [-0.462, 0.046],
                            [-0.402, -0.169],
                            [-0.288, -0.309],
                            [-0.155, -0.427],
                            [0.009, -0.467]
                          ],
                          o: [
                            [0.023, 0.239],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.216, 0.075],
                            [0.398, -0.04],
                            [0.23, -0.2],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.149, 0.28],
                            [-0.229, 0.199],
                            [-0.293, 0.126],
                            [-0.485, 0.049],
                            [-0.391, -0.122],
                            [-0.283, -0.261],
                            [-0.167, -0.385],
                            [-0.044, -0.438],
                            [0.103, -0.413],
                            [0.232, -0.329],
                            [0.349, -0.212],
                            [0.485, -0.049],
                            [0.41, 0.16],
                            [0.288, 0.308],
                            [0.163, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [-15.368, 2.002],
                            [-15.204, 2.686],
                            [-14.835, 3.239],
                            [-14.28, 3.594],
                            [-13.527, 3.663],
                            [-12.597, 3.316],
                            [-12.124, 2.485],
                            [-10.835, 2.356],
                            [-11.116, 3.29],
                            [-11.681, 4.021],
                            [-12.478, 4.498],
                            [-13.418, 4.738],
                            [-14.731, 4.628],
                            [-15.754, 4.055],
                            [-16.443, 3.088],
                            [-16.764, 1.794],
                            [-16.686, 0.532],
                            [-16.194, -0.567],
                            [-15.322, -1.378],
                            [-14.106, -1.766],
                            [-12.775, -1.585],
                            [-11.727, -0.882],
                            [-11.061, 0.221],
                            [-10.832, 1.548]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 10,
                      ty: 'sh',
                      ix: 11,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.1, 0.191],
                            [0.15, 0.129],
                            [0.2, 0.069],
                            [0.23, -0.023],
                            [0.19, -0.107],
                            [0.128, -0.165],
                            [0.068, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.038, -0.214],
                            [-0.091, -0.192],
                            [-0.142, -0.139],
                            [-0.193, -0.077],
                            [-0.239, 0.024],
                            [-0.183, 0.099],
                            [-0.121, 0.157],
                            [-0.059, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [-12.283, 0.79],
                            [-12.488, 0.183],
                            [-12.85, -0.3],
                            [-13.363, -0.61],
                            [-13.999, -0.691],
                            [-14.642, -0.494],
                            [-15.11, -0.097],
                            [-15.392, 0.45],
                            [-15.459, 1.108]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 11,
                      ty: 'sh',
                      ix: 12,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.224, 0.248],
                            [0.045, 0.454],
                            [0.097, 0.16],
                            [0.153, 0.081],
                            [0.186, 0.013],
                            [0.191, -0.019],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.478, -0.047],
                            [0.224, -0.255],
                            [-0.027, -0.271],
                            [-0.097, -0.167],
                            [-0.144, -0.082],
                            [-0.179, -0.015],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-23.347, 0.874],
                            [-20.9, 0.627],
                            [-19.847, 0.184],
                            [-19.579, -0.879],
                            [-19.764, -1.524],
                            [-20.139, -1.896],
                            [-20.636, -2.04],
                            [-21.189, -2.032],
                            [-23.613, -1.79]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 12,
                      ty: 'sh',
                      ix: 13,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.51, -0.359],
                            [-0.073, -0.724],
                            [0.093, -0.282],
                            [0.143, -0.183],
                            [0.152, -0.087],
                            [0.069, -0.031],
                            [0, 0],
                            [-0.15, -0.05],
                            [-0.142, -0.13],
                            [-0.102, -0.215],
                            [-0.033, -0.334],
                            [-0.113, -0.406],
                            [-0.177, -0.159],
                            [0, 0],
                            [0.046, 0.22],
                            [0.021, 0.207],
                            [0.076, 0.273],
                            [0.145, 0.17],
                            [0.232, 0.066],
                            [0.343, -0.035],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.939, -0.094],
                            [0.511, 0.358],
                            [0.04, 0.406],
                            [-0.093, 0.275],
                            [-0.143, 0.175],
                            [-0.144, 0.087],
                            [0, 0],
                            [0.129, 0.003],
                            [0.157, 0.048],
                            [0.141, 0.123],
                            [0.11, 0.214],
                            [0.05, 0.501],
                            [0.121, 0.398],
                            [0, 0],
                            [-0.13, -0.172],
                            [-0.038, -0.221],
                            [-0.039, -0.39],
                            [-0.076, -0.282],
                            [-0.147, -0.179],
                            [-0.223, -0.066],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-25.228, -2.857],
                            [-21.157, -3.266],
                            [-18.983, -2.868],
                            [-18.108, -1.244],
                            [-18.186, -0.212],
                            [-18.538, 0.476],
                            [-18.981, 0.869],
                            [-19.301, 1.046],
                            [-19.298, 1.071],
                            [-18.881, 1.15],
                            [-18.431, 1.418],
                            [-18.068, 1.924],
                            [-17.853, 2.747],
                            [-17.608, 4.109],
                            [-17.162, 4.945],
                            [-18.762, 5.105],
                            [-19.025, 4.516],
                            [-19.115, 3.873],
                            [-19.287, 2.878],
                            [-19.619, 2.2],
                            [-20.188, 1.834],
                            [-21.037, 1.787],
                            [-23.233, 2.007],
                            [-22.881, 5.518],
                            [-24.374, 5.667]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'mm',
                      mm: 1,
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.086274509804, 0.086274509804, 0.090196078431, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [45.089, 69.804], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 17,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198],
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0],
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198]
                          ],
                          v: [
                            [-40.361, -9.243],
                            [37.723, -17.073],
                            [42.101, -13.492],
                            [43.942, 4.864],
                            [40.361, 9.243],
                            [-37.723, 17.073],
                            [-42.102, 13.492],
                            [-43.943, -4.864]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 3
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0.5, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [44.663, 68.331], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198],
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0],
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198]
                          ],
                          v: [
                            [-40.361, -9.243],
                            [37.723, -17.073],
                            [42.101, -13.492],
                            [43.942, 4.864],
                            [40.361, 9.243],
                            [-37.723, 17.073],
                            [-42.102, 13.492],
                            [-43.943, -4.864]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [44.663, 68.331], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-0.174, 0.096],
                            [0, 0],
                            [0.036, -0.168],
                            [0, 0],
                            [0.072, 0.186],
                            [0, 0],
                            [0.078, 0.006],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.15, -0.083],
                            [0, 0],
                            [-0.04, 0.195],
                            [0, 0],
                            [-0.028, -0.072],
                            [0, 0],
                            [-0.199, -0.014]
                          ],
                          v: [
                            [-7.292, -0.269],
                            [7.138, -8.243],
                            [7.43, -8.027],
                            [4.052, 8.11],
                            [3.67, 8.141],
                            [0.833, 0.78],
                            [0.66, 0.652],
                            [-7.209, 0.106]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [117.206, 8.577], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 5,
                  hd: false
                }
              ],
              ip: 0,
              op: 85.2,
              st: -12,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 3,
              ty: 2,
              cl: 'png',
              parent: 2,
              tt: 1,
              refId: 'image_2',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [35.914, 27.513, 0], ix: 2, l: 2 },
                a: { a: 0, k: [256, 256, 0], ix: 1, l: 2 },
                s: { a: 0, k: [-8, 8, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ip: 0,
              op: 85.2,
              st: -12,
              bm: 0
            },
            {
              ddd: 0,
              ind: 4,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 4.8,
                      s: [0]
                    },
                    { t: 10.8, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -12,
                      s: [25]
                    },
                    { t: 34.8, s: [0] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -12,
                      s: [37.659, 111.882, 0],
                      to: [142.92, 42.625, 0],
                      ti: [-7.333, 19.833, 0]
                    },
                    { t: 34.8, s: [258.216, 76.034, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [62.461, 43.062, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-10.843, 2.108],
                            [0, 0],
                            [-2.108, -10.842],
                            [10.843, -2.108],
                            [2.107, 10.842]
                          ],
                          o: [
                            [0, 0],
                            [10.843, -2.108],
                            [2.107, 10.843],
                            [-10.842, 2.108],
                            [-2.108, -10.843]
                          ],
                          v: [
                            [-3.816, -19.633],
                            [-3.816, -19.633],
                            [19.633, -3.816],
                            [3.816, 19.632],
                            [-19.632, 3.816]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [36.769, 27.214], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: 0,
              op: 85.2,
              st: -12,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 5,
              ty: 4,
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 6,
                      s: [0]
                    },
                    { t: 12, s: [100] }
                  ],
                  ix: 11
                },
                r: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0.667], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: -10.8,
                      s: [25]
                    },
                    { t: 36, s: [0] }
                  ],
                  ix: 10
                },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0.42, y: 1 },
                      o: { x: 0.58, y: 0 },
                      t: -10.8,
                      s: [37.659, 113.882, 0],
                      to: [142.92, 42.625, 0],
                      ti: [-7.333, 19.833, 0]
                    },
                    { t: 36, s: [211.103, 75.749, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [62.461, 43.062, 0], ix: 1, l: 2 },
                s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.095, 0.171],
                            [-0.147, 0.144],
                            [-0.191, 0.1],
                            [-0.207, 0.021],
                            [-0.056, -0.002],
                            [-0.065, -0.002],
                            [0, 0],
                            [0.105, -0.002],
                            [0.096, -0.009],
                            [0.197, -0.116],
                            [0.133, -0.198],
                            [0.061, -0.272],
                            [-0.035, -0.342],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.023, -0.171],
                            [0.103, -0.171],
                            [0.153, -0.152],
                            [0.191, -0.1],
                            [0.159, -0.015],
                            [0.065, 0.002],
                            [0, 0],
                            [-0.097, -0.006],
                            [-0.097, -0.006],
                            [-0.23, 0.023],
                            [-0.19, 0.108],
                            [-0.133, 0.19],
                            [-0.061, 0.271],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [21.493, -5.167],
                            [22.77, -5.295],
                            [22.889, -4.1],
                            [22.913, -4.103],
                            [23.092, -4.615],
                            [23.467, -5.087],
                            [23.983, -5.464],
                            [24.58, -5.645],
                            [24.903, -5.665],
                            [25.098, -5.66],
                            [25.228, -4.347],
                            [24.926, -4.353],
                            [24.637, -4.348],
                            [23.996, -4.139],
                            [23.51, -3.68],
                            [23.218, -2.988],
                            [23.178, -2.067],
                            [23.473, 0.869],
                            [22.111, 1.007]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 1,
                      ty: 'sh',
                      ix: 2,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.152, -0.153],
                            [-0.217, -0.083],
                            [-0.287, 0.029],
                            [-0.222, 0.191],
                            [-0.085, 0.354],
                            [0, 0],
                            [0.149, -0.281],
                            [0.228, -0.208],
                            [0.302, -0.118],
                            [0.335, -0.033],
                            [0.39, 0.122],
                            [0.291, 0.26],
                            [0.175, 0.384],
                            [0.048, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.349, 0.212],
                            [-0.462, 0.046],
                            [-0.403, -0.169],
                            [-0.288, -0.309],
                            [-0.155, -0.427],
                            [0.01, -0.467]
                          ],
                          o: [
                            [0.024, 0.239],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.217, 0.075],
                            [0.398, -0.04],
                            [0.229, -0.199],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.148, 0.28],
                            [-0.23, 0.199],
                            [-0.293, 0.126],
                            [-0.485, 0.049],
                            [-0.39, -0.122],
                            [-0.284, -0.261],
                            [-0.168, -0.385],
                            [-0.044, -0.438],
                            [0.104, -0.413],
                            [0.233, -0.329],
                            [0.348, -0.212],
                            [0.486, -0.049],
                            [0.41, 0.159],
                            [0.288, 0.308],
                            [0.163, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [16.265, -1.17],
                            [16.431, -0.486],
                            [16.799, 0.067],
                            [17.353, 0.423],
                            [18.108, 0.492],
                            [19.038, 0.145],
                            [19.509, -0.686],
                            [20.799, -0.816],
                            [20.518, 0.118],
                            [19.954, 0.85],
                            [19.156, 1.327],
                            [18.215, 1.566],
                            [16.902, 1.457],
                            [15.88, 0.884],
                            [15.192, -0.084],
                            [14.869, -1.378],
                            [14.947, -2.641],
                            [15.441, -3.74],
                            [16.312, -4.55],
                            [17.527, -4.938],
                            [18.861, -4.757],
                            [19.908, -4.054],
                            [20.572, -2.951],
                            [20.803, -1.624]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 2,
                      ty: 'sh',
                      ix: 3,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.099, 0.191],
                            [0.149, 0.129],
                            [0.2, 0.069],
                            [0.231, -0.023],
                            [0.191, -0.107],
                            [0.128, -0.166],
                            [0.067, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.037, -0.214],
                            [-0.092, -0.192],
                            [-0.143, -0.139],
                            [-0.193, -0.077],
                            [-0.239, 0.024],
                            [-0.183, 0.099],
                            [-0.121, 0.157],
                            [-0.06, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [19.351, -2.382],
                            [19.147, -2.99],
                            [18.785, -3.472],
                            [18.27, -3.782],
                            [17.635, -3.863],
                            [16.991, -3.667],
                            [16.525, -3.269],
                            [16.243, -2.722],
                            [16.176, -2.064]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 3,
                      ty: 'sh',
                      ix: 4,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [5.004, -3.514],
                            [6.449, -3.658],
                            [8.116, 0.83],
                            [8.14, 0.827],
                            [8.837, -3.897],
                            [10.21, -4.035],
                            [11.781, 0.463],
                            [11.806, 0.46],
                            [12.598, -4.274],
                            [13.983, -4.413],
                            [12.666, 1.954],
                            [11.27, 2.093],
                            [9.665, -2.376],
                            [9.64, -2.374],
                            [8.965, 2.325],
                            [7.533, 2.468]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 4,
                      ty: 'sh',
                      ix: 5,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.152, -0.153],
                            [-0.217, -0.083],
                            [-0.287, 0.029],
                            [-0.222, 0.192],
                            [-0.085, 0.355],
                            [0, 0],
                            [0.149, -0.281],
                            [0.228, -0.208],
                            [0.302, -0.118],
                            [0.334, -0.034],
                            [0.39, 0.122],
                            [0.291, 0.261],
                            [0.175, 0.384],
                            [0.048, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.349, 0.212],
                            [-0.462, 0.046],
                            [-0.403, -0.169],
                            [-0.288, -0.309],
                            [-0.156, -0.427],
                            [0.01, -0.468]
                          ],
                          o: [
                            [0.024, 0.238],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.217, 0.075],
                            [0.398, -0.04],
                            [0.229, -0.199],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.148, 0.28],
                            [-0.23, 0.2],
                            [-0.293, 0.126],
                            [-0.486, 0.048],
                            [-0.39, -0.122],
                            [-0.284, -0.261],
                            [-0.168, -0.385],
                            [-0.044, -0.438],
                            [0.104, -0.413],
                            [0.233, -0.329],
                            [0.348, -0.212],
                            [0.486, -0.049],
                            [0.41, 0.16],
                            [0.288, 0.309],
                            [0.162, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [0.337, 0.429],
                            [0.502, 1.111],
                            [0.871, 1.665],
                            [1.426, 2.019],
                            [2.181, 2.088],
                            [3.111, 1.742],
                            [3.582, 0.911],
                            [4.871, 0.782],
                            [4.59, 1.714],
                            [4.025, 2.446],
                            [3.228, 2.924],
                            [2.288, 3.163],
                            [0.975, 3.053],
                            [-0.048, 2.48],
                            [-0.736, 1.512],
                            [-1.059, 0.218],
                            [-0.98, -1.043],
                            [-0.488, -2.142],
                            [0.384, -2.954],
                            [1.6, -3.34],
                            [2.932, -3.16],
                            [3.98, -2.457],
                            [4.645, -1.355],
                            [4.874, -0.027]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 5,
                      ty: 'sh',
                      ix: 6,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.099, 0.191],
                            [0.149, 0.13],
                            [0.2, 0.068],
                            [0.231, -0.023],
                            [0.191, -0.108],
                            [0.128, -0.165],
                            [0.067, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.037, -0.214],
                            [-0.092, -0.192],
                            [-0.143, -0.139],
                            [-0.192, -0.078],
                            [-0.239, 0.024],
                            [-0.183, 0.098],
                            [-0.121, 0.157],
                            [-0.06, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [3.423, -0.785],
                            [3.218, -1.392],
                            [2.856, -1.874],
                            [2.342, -2.184],
                            [1.707, -2.266],
                            [1.064, -2.068],
                            [0.598, -1.673],
                            [0.314, -1.126],
                            [0.247, -0.467]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 6,
                      ty: 'sh',
                      ix: 7,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-3.915, -2.618],
                            [-2.553, -2.756],
                            [-1.934, 3.417],
                            [-3.296, 3.554]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 7,
                      ty: 'sh',
                      ix: 8,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-4.15, -4.972],
                            [-2.79, -5.107],
                            [-2.66, -3.818],
                            [-4.02, -3.681]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 8,
                      ty: 'sh',
                      ix: 9,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-10.751, -1.933],
                            [-9.27, -2.081],
                            [-7.23, 2.502],
                            [-7.207, 2.499],
                            [-6.178, -2.392],
                            [-4.769, -2.533],
                            [-6.358, 3.861],
                            [-7.887, 4.014]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 9,
                      ty: 'sh',
                      ix: 10,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [-0.086, -0.216],
                            [-0.153, -0.153],
                            [-0.218, -0.083],
                            [-0.286, 0.029],
                            [-0.222, 0.191],
                            [-0.085, 0.354],
                            [0, 0],
                            [0.149, -0.281],
                            [0.229, -0.208],
                            [0.302, -0.118],
                            [0.334, -0.033],
                            [0.39, 0.122],
                            [0.292, 0.26],
                            [0.176, 0.384],
                            [0.047, 0.477],
                            [-0.096, 0.403],
                            [-0.225, 0.32],
                            [-0.348, 0.212],
                            [-0.462, 0.046],
                            [-0.402, -0.169],
                            [-0.288, -0.309],
                            [-0.155, -0.427],
                            [0.009, -0.467]
                          ],
                          o: [
                            [0.023, 0.239],
                            [0.094, 0.216],
                            [0.152, 0.154],
                            [0.216, 0.075],
                            [0.398, -0.04],
                            [0.23, -0.2],
                            [0, 0],
                            [-0.038, 0.341],
                            [-0.149, 0.28],
                            [-0.229, 0.199],
                            [-0.293, 0.126],
                            [-0.485, 0.049],
                            [-0.391, -0.122],
                            [-0.283, -0.261],
                            [-0.167, -0.385],
                            [-0.044, -0.438],
                            [0.103, -0.413],
                            [0.232, -0.329],
                            [0.349, -0.212],
                            [0.485, -0.049],
                            [0.41, 0.16],
                            [0.288, 0.308],
                            [0.163, 0.418],
                            [0, 0]
                          ],
                          v: [
                            [-15.368, 2.002],
                            [-15.204, 2.686],
                            [-14.835, 3.239],
                            [-14.28, 3.594],
                            [-13.527, 3.663],
                            [-12.597, 3.316],
                            [-12.124, 2.485],
                            [-10.835, 2.356],
                            [-11.116, 3.29],
                            [-11.681, 4.021],
                            [-12.478, 4.498],
                            [-13.418, 4.738],
                            [-14.731, 4.628],
                            [-15.754, 4.055],
                            [-16.443, 3.088],
                            [-16.764, 1.794],
                            [-16.686, 0.532],
                            [-16.194, -0.567],
                            [-15.322, -1.378],
                            [-14.106, -1.766],
                            [-12.775, -1.585],
                            [-11.727, -0.882],
                            [-11.061, 0.221],
                            [-10.832, 1.548]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 10,
                      ty: 'sh',
                      ix: 11,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0.1, 0.191],
                            [0.15, 0.129],
                            [0.2, 0.069],
                            [0.23, -0.023],
                            [0.19, -0.107],
                            [0.128, -0.165],
                            [0.068, -0.208],
                            [-0.015, -0.232]
                          ],
                          o: [
                            [-0.038, -0.214],
                            [-0.091, -0.192],
                            [-0.142, -0.139],
                            [-0.193, -0.077],
                            [-0.239, 0.024],
                            [-0.183, 0.099],
                            [-0.121, 0.157],
                            [-0.059, 0.207],
                            [0, 0]
                          ],
                          v: [
                            [-12.283, 0.79],
                            [-12.488, 0.183],
                            [-12.85, -0.3],
                            [-13.363, -0.61],
                            [-13.999, -0.691],
                            [-14.642, -0.494],
                            [-15.11, -0.097],
                            [-15.392, 0.45],
                            [-15.459, 1.108]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 11,
                      ty: 'sh',
                      ix: 12,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.224, 0.248],
                            [0.045, 0.454],
                            [0.097, 0.16],
                            [0.153, 0.081],
                            [0.186, 0.013],
                            [0.191, -0.019],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.478, -0.047],
                            [0.224, -0.255],
                            [-0.027, -0.271],
                            [-0.097, -0.167],
                            [-0.144, -0.082],
                            [-0.179, -0.015],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-23.347, 0.874],
                            [-20.9, 0.627],
                            [-19.847, 0.184],
                            [-19.579, -0.879],
                            [-19.764, -1.524],
                            [-20.139, -1.896],
                            [-20.636, -2.04],
                            [-21.189, -2.032],
                            [-23.613, -1.79]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ind: 12,
                      ty: 'sh',
                      ix: 13,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.51, -0.359],
                            [-0.073, -0.724],
                            [0.093, -0.282],
                            [0.143, -0.183],
                            [0.152, -0.087],
                            [0.069, -0.031],
                            [0, 0],
                            [-0.15, -0.05],
                            [-0.142, -0.13],
                            [-0.102, -0.215],
                            [-0.033, -0.334],
                            [-0.113, -0.406],
                            [-0.177, -0.159],
                            [0, 0],
                            [0.046, 0.22],
                            [0.021, 0.207],
                            [0.076, 0.273],
                            [0.145, 0.17],
                            [0.232, 0.066],
                            [0.343, -0.035],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [0.939, -0.094],
                            [0.511, 0.358],
                            [0.04, 0.406],
                            [-0.093, 0.275],
                            [-0.143, 0.175],
                            [-0.144, 0.087],
                            [0, 0],
                            [0.129, 0.003],
                            [0.157, 0.048],
                            [0.141, 0.123],
                            [0.11, 0.214],
                            [0.05, 0.501],
                            [0.121, 0.398],
                            [0, 0],
                            [-0.13, -0.172],
                            [-0.038, -0.221],
                            [-0.039, -0.39],
                            [-0.076, -0.282],
                            [-0.147, -0.179],
                            [-0.223, -0.066],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0]
                          ],
                          v: [
                            [-25.228, -2.857],
                            [-21.157, -3.266],
                            [-18.983, -2.868],
                            [-18.108, -1.244],
                            [-18.186, -0.212],
                            [-18.538, 0.476],
                            [-18.981, 0.869],
                            [-19.301, 1.046],
                            [-19.298, 1.071],
                            [-18.881, 1.15],
                            [-18.431, 1.418],
                            [-18.068, 1.924],
                            [-17.853, 2.747],
                            [-17.608, 4.109],
                            [-17.162, 4.945],
                            [-18.762, 5.105],
                            [-19.025, 4.516],
                            [-19.115, 3.873],
                            [-19.287, 2.878],
                            [-19.619, 2.2],
                            [-20.188, 1.834],
                            [-21.037, 1.787],
                            [-23.233, 2.007],
                            [-22.881, 5.518],
                            [-24.374, 5.667]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'mm',
                      mm: 1,
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.086274509804, 0.086274509804, 0.090196078431, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [45.089, 69.804], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 17,
                  cix: 2,
                  bm: 0,
                  ix: 2,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198],
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0],
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198]
                          ],
                          v: [
                            [-40.361, -9.243],
                            [37.723, -17.073],
                            [42.101, -13.492],
                            [43.942, 4.864],
                            [40.361, 9.243],
                            [-37.723, 17.073],
                            [-42.102, 13.492],
                            [-43.943, -4.864]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'st',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 3
                      },
                      o: { a: 0, k: 100, ix: 4 },
                      w: { a: 0, k: 0.5, ix: 5 },
                      lc: 1,
                      lj: 1,
                      ml: 4,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [44.663, 68.331], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 2,
                  cix: 2,
                  bm: 0,
                  ix: 3,
                  hd: false
                },
                {
                  ty: 'gr',
                  it: [
                    {
                      ind: 0,
                      ty: 'sh',
                      ix: 1,
                      ks: {
                        a: 0,
                        k: {
                          i: [
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198],
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0]
                          ],
                          o: [
                            [0, 0],
                            [2.198, -0.22],
                            [0, 0],
                            [0.22, 2.198],
                            [0, 0],
                            [-2.198, 0.22],
                            [0, 0],
                            [-0.22, -2.198]
                          ],
                          v: [
                            [-40.361, -9.243],
                            [37.723, -17.073],
                            [42.101, -13.492],
                            [43.942, 4.864],
                            [40.361, 9.243],
                            [-37.723, 17.073],
                            [-42.102, 13.492],
                            [-43.943, -4.864]
                          ],
                          c: true
                        },
                        ix: 2
                      },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.682352941176, 0.839215746113, 0.733333333333, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [44.663, 68.331], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 4,
                  cix: 2,
                  bm: 0,
                  ix: 4,
                  hd: false
                }
              ],
              ip: -16.8,
              op: 84,
              st: -10.8,
              ct: 1,
              bm: 0
            }
          ]
        },
        {
          id: 'comp_2',
          fr: 30,
          layers: [
            {
              ddd: 0,
              ind: 1,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 229,
                      s: [0]
                    },
                    { t: 288, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 229,
                      s: [5341.5, 1739.5, 0],
                      to: [-346.667, 0, 0],
                      ti: [346.667, 0, 0]
                    },
                    { t: 288, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [853.408, 703.859],
                        [575.05, 703.859],
                        [574.701, 921.026],
                        [853.06, 921.026]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 2,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 231,
                      s: [0]
                    },
                    { t: 290, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 231,
                      s: [5341.5, 1739.5, 0],
                      to: [-346.667, 0, 0],
                      ti: [346.667, 0, 0]
                    },
                    { t: 290, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1109.049, 700.979],
                        [857.674, 701.427],
                        [858.353, 1109.026],
                        [1109.728, 1108.578]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 3,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 233,
                      s: [0]
                    },
                    { t: 292, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 233,
                      s: [5341.5, 1739.5, 0],
                      to: [-346.667, 0, 0],
                      ti: [346.667, 0, 0]
                    },
                    { t: 292, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1359.453, 707.683],
                        [1111.535, 704.898],
                        [1111.535, 949.499],
                        [1369.201, 949.499]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 4,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 235,
                      s: [0]
                    },
                    { t: 294, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 235,
                      s: [5341.5, 1739.5, 0],
                      to: [-346.667, 0, 0],
                      ti: [346.667, 0, 0]
                    },
                    { t: 294, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1848.211, 656.058],
                        [1371.536, 656.155],
                        [1370.99, 1106.161],
                        [1847.665, 1106.064]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 5,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 222,
                      s: [3261.5, 2256.5, 0],
                      to: [0, -86.167, 0],
                      ti: [0, 86.167, 0]
                    },
                    { t: 269, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [543.459, 207.398],
                        [304.238, 207.516],
                        [304.238, 292.468],
                        [543.459, 292.35]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 6,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 224,
                      s: [3261.5, 2256.5, 0],
                      to: [0, -86.167, 0],
                      ti: [0, 86.167, 0]
                    },
                    { t: 271, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [522.708, 294.935],
                        [313.81, 294.935],
                        [313.81, 361.782],
                        [522.708, 361.782]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 7,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 226,
                      s: [3261.5, 2256.5, 0],
                      to: [0, -86.167, 0],
                      ti: [0, 86.167, 0]
                    },
                    { t: 273, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [533.849, 357.165],
                        [311.025, 357.165],
                        [311.025, 408.693],
                        [533.849, 408.693]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 8,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 228,
                      s: [3261.5, 2256.5, 0],
                      to: [0, -86.167, 0],
                      ti: [0, 86.167, 0]
                    },
                    { t: 275, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [498.724, 412.471],
                        [328.006, 412.227],
                        [328.006, 469.082],
                        [498.724, 469.326]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 9,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 230,
                      s: [3261.5, 2256.5, 0],
                      to: [0, -86.167, 0],
                      ti: [0, 86.167, 0]
                    },
                    { t: 277, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [499.033, 466.428],
                        [297.499, 467.019],
                        [297.499, 522.056],
                        [499.033, 521.465]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 10,
              ty: 4,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [1572.337, 1186.725, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-1774.905, -408.994, 0], ix: 1, l: 2 },
                s: { a: 0, k: [121.505, 100, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: {
                        a: 0,
                        k: [0.086274509804, 0.094117654539, 0.098039223166, 1],
                        ix: 4
                      },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 11,
              ty: 4,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3913.787, 2395.455, 0], ix: 2, l: 2 },
                a: { a: 0, k: [652.287, 655.955, 0], ix: 1, l: 2 },
                s: { a: 0, k: [100, 97.631, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [4225.366, 886.365], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [652.287, 1099.137], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 12,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2734.387, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 13,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 222,
                      s: [0]
                    },
                    { t: 281, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 222,
                      s: [3261.5, 2871.5, 0],
                      to: [0, -188.667, 0],
                      ti: [0, 188.667, 0]
                    },
                    { t: 281, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1098.976, 311.265],
                        [491.331, 311.265],
                        [491.331, 385.076],
                        [1098.976, 385.076]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 14,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2734.387, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 15,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 226,
                      s: [0]
                    },
                    { t: 285, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 226,
                      s: [3261.5, 2871.5, 0],
                      to: [0, -188.667, 0],
                      ti: [0, 188.667, 0]
                    },
                    { t: 285, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1091.227, 385.868],
                        [489.518, 385.868],
                        [489.518, 458.286],
                        [1091.227, 458.286]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 16,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2734.387, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 17,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 230,
                      s: [0]
                    },
                    { t: 289, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 230,
                      s: [3261.5, 2871.5, 0],
                      to: [0, -188.667, 0],
                      ti: [0, 188.667, 0]
                    },
                    { t: 289, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1063.36, 464.903],
                        [493.145, 465.036],
                        [493.145, 538.846],
                        [1063.36, 538.713]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 18,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [2734.387, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 19,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 234,
                      s: [0]
                    },
                    { t: 293, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 234,
                      s: [3261.5, 2871.5, 0],
                      to: [0, -188.667, 0],
                      ti: [0, 188.667, 0]
                    },
                    { t: 293, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1064.612, 545.394],
                        [491.331, 544.972],
                        [491.331, 650.864],
                        [1064.612, 651.286]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 20,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5035.124, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 21,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 223,
                      s: [0]
                    },
                    { t: 282, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 223,
                      s: [3261.5, 2838.5, 0],
                      to: [0, -183.167, 0],
                      ti: [0, 183.167, 0]
                    },
                    { t: 282, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1603.225, 311.867],
                        [1119.784, 310.616],
                        [1119.784, 404.208],
                        [1603.225, 405.46]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 22,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5035.124, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 23,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 225,
                      s: [0]
                    },
                    { t: 284, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 225,
                      s: [3261.5, 2838.5, 0],
                      to: [0, -183.167, 0],
                      ti: [0, 183.167, 0]
                    },
                    { t: 284, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1600.362, 399.255],
                        [1120.44, 399.721],
                        [1120.183, 480.111],
                        [1600.104, 479.645]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 24,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5035.124, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 25,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 227,
                      s: [0]
                    },
                    { t: 286, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 227,
                      s: [3261.5, 2838.5, 0],
                      to: [0, -183.167, 0],
                      ti: [0, 183.167, 0]
                    },
                    { t: 286, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1846.654, 485.903],
                        [1109.196, 485.749],
                        [1109.196, 563.035],
                        [1846.654, 563.189]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 26,
              ty: 4,
              td: 1,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [5035.124, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [288.417, 66.508, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ef: [
                {
                  ty: 5,
                  np: 4,
                  ix: 1,
                  en: 1,
                  ef: [
                    {
                      ty: 0,
                      ix: 1,
                      v: { a: 0, k: 77.3, ix: 1 }
                    },
                    {
                      ty: 7,
                      ix: 2,
                      v: { a: 0, k: 1, ix: 2 }
                    }
                  ]
                }
              ],
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 27,
              ty: 2,
              cl: 'ai',
              tt: 1,
              refId: 'image_3',
              sr: 1,
              ks: {
                o: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.333], y: [0] },
                      t: 240,
                      s: [0]
                    },
                    { t: 299, s: [100] }
                  ],
                  ix: 11
                },
                r: { a: 0, k: 0, ix: 10 },
                p: {
                  a: 1,
                  k: [
                    {
                      i: { x: 0, y: 1 },
                      o: { x: 0.333, y: 0 },
                      t: 240,
                      s: [3261.5, 2838.5, 0],
                      to: [0, -183.167, 0],
                      ti: [0, 183.167, 0]
                    },
                    { t: 299, s: [3261.5, 1739.5, 0] }
                  ],
                  ix: 2,
                  l: 2
                },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              hasMask: true,
              masksProperties: [
                {
                  inv: false,
                  mode: 'a',
                  pt: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1806.929, 571.027],
                        [966.229, 571.01],
                        [966.229, 602.05],
                        [1806.929, 602.067]
                      ],
                      c: true
                    },
                    ix: 1
                  },
                  o: { a: 0, k: 100, ix: 3 },
                  x: { a: 0, k: 0, ix: 4 }
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 28,
              ty: 4,
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3811.328, 818.118, 0], ix: 2, l: 2 },
                a: { a: 0, k: [-2158.841, -1318.436, 0], ix: 1, l: 2 },
                s: { a: 0, k: [568.917, 72.19, 100], ix: 6, l: 2 }
              },
              ao: 0,
              shapes: [
                {
                  ty: 'gr',
                  it: [
                    {
                      ty: 'rc',
                      d: 1,
                      s: { a: 0, k: [767.871, 1818.885], ix: 2 },
                      p: { a: 0, k: [0, 0], ix: 3 },
                      r: { a: 0, k: 0, ix: 4 },
                      hd: false
                    },
                    {
                      ty: 'fl',
                      c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                      o: { a: 0, k: 100, ix: 5 },
                      r: 1,
                      bm: 0,
                      hd: false
                    },
                    {
                      ty: 'tr',
                      p: { a: 0, k: [-2158.841, -408.994], ix: 2 },
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      r: { a: 0, k: 0, ix: 6 },
                      o: { a: 0, k: 100, ix: 7 },
                      sk: { a: 0, k: 0, ix: 4 },
                      sa: { a: 0, k: 0, ix: 5 }
                    }
                  ],
                  np: 3,
                  cix: 2,
                  bm: 0,
                  ix: 1,
                  hd: false
                }
              ],
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            },
            {
              ddd: 0,
              ind: 29,
              ty: 2,
              cl: 'ai',
              refId: 'image_3',
              sr: 1,
              ks: {
                o: { a: 0, k: 100, ix: 11 },
                r: { a: 0, k: 0, ix: 10 },
                p: { a: 0, k: [3261.5, 1739.5, 0], ix: 2, l: 2 },
                a: { a: 0, k: [960, 540, 0], ix: 1, l: 2 },
                s: { a: 0, k: [400, 400, 100], ix: 6, l: 2 }
              },
              ao: 0,
              ip: -7,
              op: 2993,
              st: -7,
              ct: 1,
              bm: 0
            }
          ]
        }
      ],
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 2,
          cl: 'ai',
          refId: 'image_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [-212.5, 463, 0],
                  to: [69.5, 0, 0],
                  ti: [-69.5, 0, 0]
                },
                { t: 37, s: [204.5, 463, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [200.5, 72.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [54, 54, 100], ix: 6, l: 2 }
          },
          ao: 0,
          ip: 0,
          op: 211,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          refId: 'comp_0',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [24, 672.932, 0], ix: 2, l: 2 },
            a: { a: 0, k: [1350, 675, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          w: 2700,
          h: 1350,
          ip: 0,
          op: 92,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          refId: 'comp_1',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: -14, ix: 10 },
            p: { a: 0, k: [603.103, 131.779, 0], ix: 2, l: 2 },
            a: { a: 0, k: [182, 149, 0], ix: 1, l: 2 },
            s: { a: 0, k: [-100, 100, 100], ix: 6, l: 2 }
          },
          ao: 0,
          w: 364,
          h: 298,
          ip: 0,
          op: 85.2,
          st: 0,
          ct: 1,
          bm: 0
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          refId: 'comp_2',
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: {
              a: 1,
              k: [
                {
                  i: { x: 0, y: 1 },
                  o: { x: 0.333, y: 0 },
                  t: 0,
                  s: [1141.447, 384, 0],
                  to: [-96, 0, 0],
                  ti: [96, 0, 0]
                },
                { t: 30, s: [565.447, 384, 0] }
              ],
              ix: 2,
              l: 2
            },
            a: { a: 0, k: [3261.5, 1739.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [13.4, 13.4, 100], ix: 6, l: 2 }
          },
          ao: 0,
          w: 6523,
          h: 3479,
          ip: 0,
          op: 2771,
          st: -229,
          bm: 0
        }
      ],
      markers: []
    });
  });
});
